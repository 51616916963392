import {
    Box,
    Grid,
    Paper,
} from "@mui/material";
import TopBar from "../../components/topBar/TopBar";
import { useEffect, useState } from "react";
import SendRequest from "../../request/SendRequest";
import UserProfileInfo from "../../components/UserProfileInfo/UserProfileInfo";
import { Tabs } from "../../components";
import Loading from "../../components/Loading/loading";

function UserProfile() {

    const [data, setData] = useState([{
        name: "",
        age: "",
        gender: "",
        subscribed_service_detail: [{
            contractNo: "",
            install_address: "",
            svc_plan_desc: "",
            svc_type_desc: "",
            svc_subsribe_agree_period: "",
            price: "",
        }],
    }]);
    const [tabValue, setTabValue] = useState(0);
    const [tabs, setTabs] = useState([{
        key: "",
        label: "",
    }]);
    const [loading,setLoading] = useState(true)


    useEffect(() => {
        fetchContent();
    }, []);


    const fetchContent = async () => {
        const fetchData = await SendRequest.post({ url: process.env.REACT_APP_API_URL + 'subscription/userprofile' }).then(response => {
            if (response.status === 200) {
                let data = response.data.data.message.users.map((data, key) => (
                    {
                        name: data.name,
                        age: data.age,
                        gender: data.gender,
                        subscribed_service_detail: data.subscribedServiceDetail.map((detail, key) => (
                            {
                                contractNo: detail.contractNo,
                                install_address: detail.installAddress,
                                svc_plan_desc: detail.svcPlanDesc,
                                svc_type_desc: detail.svcTypeDesc,
                                svc_subsribe_agree_period: detail.svcSubsribeAgreePeriod,
                                price: detail.price,
                            }
                        ))
                    }
                ));
                setData(data);
                let tabs = response.data.data.message.users.map((data, key) => (
                    {
                        key: key,
                        label: data.name,
                    }
                ));
                setTabs(tabs);
            }
        })
        Promise.all([fetchData]).then(() => {
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    };


    return(
        <Grid textAlign="center">
            <Loading show={loading} />
            <TopBar/>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <Box className="page-title">用戶資料</Box>
                </Grid>
            </Grid>
            <Grid marginTop={1} container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <Tabs options={tabs} value={tabValue} onClick={(value) => setTabValue(value)} />
                    <Box marginTop={1}>
                        <Paper>
                        <UserProfileInfo data={data[tabValue]} />
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    )
}
export default UserProfile
