import React from "react";
import './IndexCard.scss'

import {
    Box, Grid, Paper
} from "@mui/material";

function IndexCard(props) {
    return (
            <a className="index-card" href={props.href}>
                <Paper>
                    <Grid container style={{height: '100%'}}>
                        <Grid item className="image" xs={5} md={12} style={{height: '100%'}}>
                            <Box item className="image-wrapper" style={{height: '100%'}}><img src={props.thumbnailSrc}/>
                            {/* <Box style={{
                                position: 'absolute',
                                bottom: '5%',
                                right: '5%',
                                width: '18%',
                                display: props.type === 'video' ? '' : 'none',
                            }}>
                                <img src={StartButton} alt="Play"
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}/>
                            </Box> */}
                            </Box>
                        </Grid>
                        <Grid item xs={7} md={12} style={{margin: "auto"}}>
                            <Grid container className="description">
                                <Grid item xs={12}><span className={props.date ? "date" : "d-none"}>{props.date}</span></Grid>
                                <Grid item xs={12}><span className={props.title ? "title" : "d-none"}>{props.title}</span></Grid>
                                <Grid item xs={12}><span className={props.subtitle ? "subtitle" : "d-none"}>{props.subtitle}</span></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                
                </Paper>
            </a>
        );
}

export default IndexCard