import Card from "../Card/Card";
import styles from "./HistoryCard.module.scss";

const HistoryCard = ({ data, onClick }) => {
    return (
        <>
            <Card>
                <div className={styles["history-container"]}>
                    {
                        data.map((item, index) => (
                            <div key={"history_" + index} className={styles["history-row"]}>
                                <div className={styles["history-year"]}>{item.year}</div>
                                <div className={styles["history-content"]}>
                                    {
                                        item.data.map((item, index) => (
                                            <div key={index} className={styles["content-row"]} onClick={() => onClick(item)}>
                                                <div className={styles["label"]}>{item.label}</div>
                                                <div className={styles["arrow-right"]}></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Card>
        </>
    );
};

export default HistoryCard;