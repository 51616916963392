import * as Svg from './svg';
import styles from "./Icon.module.scss";

const Icon = ({ name, ...attrs }) => {
    const SvgIcon = () => {
        if (!name || typeof name !== "string") return null;

        let iconName = "";

        name.split("-").forEach((word) => {
            iconName += word.charAt(0).toUpperCase() + word.slice(1);
        });

        iconName += "Icon";
        
        if (iconName in Svg) {
            const IconComponent = Svg[iconName];
            return <IconComponent />;
        }

        return null;
    };

    return (
        <div className={styles["icon"]} {...attrs}>
            {SvgIcon()}
        </div>
    );
};

export default Icon;