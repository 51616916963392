import { Navigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import { Box, Grid } from '@mui/material';
import Enquiry from '../../assets/enquiry-chat.svg'
import { useEffect, useState } from 'react';


const AuthRoute = ({ children }) => {

    useEffect(() => {
        function handleResize() {
            setScreenWidth(document.getElementsByClassName('App')[0].scrollWidth);
        }
        handleResize()
        window.addEventListener('resize', handleResize);
    }, [])

    const [cookies, setCookie] = useCookies(['subscriber_token']);
    const [screenWidth, setScreenWidth] = useState(0);
    const token = cookies.subscriber_token;
    if (token) {
        return (<>{children}
            <Grid item xs={12} 
            width={screenWidth}
            style={{
                position: "fixed",
                bottom: '0',
                zIndex: '1',
            }}>
                <Box
                    position="absolute"
                    bottom="0"
                    right="0"
                    width="125px"
                    height="50px"
                    backgroundColor="#fff"
                    borderRadius="10px 0 0 0"
                    boxShadow="0 4px 8px rgba(0,0,0,0.2)"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <a href="enquiry" style={{
                                borderColor: "#FF395D",
                                borderRadius: "90px",
                                borderWidth: "1px",
                                border: "1px solid #FF395D",
                            }}>
                            <div style={{
                                color: "#FF395D",
                                borderColor: "#FF395D",
                                display: 'flex',
                                fontWeight: '700',
                                fontSize: '14px',
                                width: '105px',
                                height: '34px',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                padding: '5px',
                            }}>
                            < img src={Enquiry} alt="enquiry" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                            <div>意見查詢</div>
                        </div>
                    </a>
                </Box>
            </Grid>
        </>)
    } else {
        return <Navigate to="/login" replace />
    }
}

export default AuthRoute
