import React, { useState } from 'react';
import {
    Box,
    Grid,
    Button,
    Paper,
    FormControl,
    InputLabel,
    FilledInput, InputAdornment, FormHelperText
} from "@mui/material";
import TextField from '@mui/material/TextField';
import logo from '../assets/logo.svg';
import SendRequest from "../request/SendRequest";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';


export default function LoginPage() {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState('')
    const [keepLogin, setKeepLogin] = useState(false)
    const [userNameError, setUserNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loginMessage, setLoginMessage] = useState(t('login.login'))
    const [loginBtn, setLoginBtn] = useState(false)
    const [loginValues, setLoginValues] = useState({
        username: '',
        password: ''
    });
    const [cookies, setCookie] = useCookies(['subscriber_token']);

    const handleKeepLogin = (event) => {
        setKeepLogin(event.target.checked)
    }

    const handleLogin = () => {
        setLoginMessage(t('login.logging_in'))
        setLoginBtn(true)
        setUserNameError(false)
        setPasswordError(false)

        if (!loginValues.username) {
            setErrorMessage(t('login.username_required'))
            setUserNameError(true);
            setLoginBtn(false)
            setLoginMessage(t('login.login'))
        }
        if (!loginValues.password) {
            setErrorMessage(t('login.password_required'))
            setPasswordError(true);
            setLoginBtn(false)
            setLoginMessage(t('login.login'))
        }

        if (loginValues.username && loginValues.password) {
            const user = {
                username: loginValues.username,
                password: loginValues.password
            }
            SendRequest.post({url: process.env.REACT_APP_API_URL + 'subscriber/login',data: user}).then(response => {
                if(response.status === 200){
                    if(keepLogin){
                        setCookie('subscriber_token', response.data.data.message.token, { path: '/', maxAge: 60*60*24*30, secure: true })
                        setCookie('subscriber_id', response.data.data.message.subscriber_id, { path: '/', maxAge: 60*60*24*30, secure: true })
                    }else {
                        setCookie('subscriber_token', response.data.data.message.token, { path: '/', secure: true })
                        setCookie('subscriber_id', response.data.data.message.subscriber_id, { path: '/', secure: true })
                    }
                    navigate('/home')
                }
            }).catch(err => {
                setUserNameError(true)
                setPasswordError(true)
                console.log(passwordError);
                setErrorMessage(err.response.data.message)
            }).finally(() => {
                setLoginBtn(false)
                setLoginMessage(t('login.login'))
            })
        }

    }

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLoginValue = (event) => {
        const { name, value } = event.target;
        setLoginValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    return (
        <Grid marginTop={3} container textAlign="center" justifyContent="center" alignItems="center">
            <Grid item xs={10} sm={6} >
                <img className="pt-5 pb-3" width="200vh" src={logo} alt="Logo" loading="lazy"/>
                <Box>
                    <form onSubmit={(e) => e.preventDefault()}>
                    <Paper style={{ padding: '5%' }}>
                        <Box margin='auto'>
                            <TextField
                                name='username'
                                value={loginValues.username}
                                error={userNameError}
                                id="filled-basic"
                                onChange={handleLoginValue}
                                label={t('login.username_label')}
                                margin='normal'
                                style={{ width: '100%' }}
                                helperText={userNameError ? errorMessage : ""}
                                variant="filled" />
                            <FormControl error={passwordError} sx={{ width: '100%', marginTop: '10px' }} variant="filled">
                                <InputLabel htmlFor="outlined-adornment-password">{t('login.password_label')}</InputLabel>
                                <FilledInput
                                    name='password'
                                    id="outlined-adornment-password"
                                    value={loginValues.password}
                                    onChange={handleLoginValue}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                                {
                                    <FormHelperText style={{ color: 'crimson' }} id="outlined-weight-helper-text">{passwordError ? errorMessage : ""}</FormHelperText>
                                }
                            </FormControl>
                            <Form.Group inline='ture' className="remember-me mb-3 d-flex justify-content-between" controlId="remenberMe">
                                <Form.Check
                                    type="checkbox"
                                    label={t('login.keep_login')}
                                    checked={keepLogin}
                                    onChange={handleKeepLogin}
                                />
                                <a href="forget-password">{t('login.forget_password')}</a>
                            </Form.Group>
                            <div  inline='ture' className="remember-me mb-3 d-flex justify-content-between"><br/><a href="forget-username">{t('login.forget_username')}</a></div>
                        </Box>
                        <Box marginTop={3} >
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                size="medium"
                                sx={{ width: '150px' }}
                                disabled={loginBtn}
                                onClick={() => handleLogin()}
                            >
                                {loginMessage}
                            </Button>
                            
                        <div className='pt-5'><span>{t('login.first_login')} </span><Link to="/register" color="inherit">{t('login.click_here_register')}</Link></div>
                        </Box>
                    </Paper>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
}

function click() {
    // toggle the type attribute
    const togglePassword = document.querySelector("#togglePassword");
    const passwordField = document.querySelector("#password");
    const type = passwordField.getAttribute("type") === "password" ? "text" : "password";
    if (type === "password") {
        togglePassword.innerHTML =
            `<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        </svg>`;
    } else if (type === "text") {
        togglePassword.innerHTML =
            `<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>`;
    }
    passwordField.setAttribute("type", type);

}
