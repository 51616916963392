import {
    Box,
    Grid,
    Button,
    Typography
} from "@mui/material";
import TopBar from "../../components/topBar/TopBar";
import React from "react";
import {Link} from "react-router-dom";
import NotFoundLogo from '../../assets/404.svg'
function NotFoundPage() {



    return(
        <Grid>
            <TopBar/>
            <Grid container justifyContent="center" alignItems="center" textAlign="center" style={{ height: "100%" }}>
                <Grid item xs={10} sm={6}>
                    <Box  justifyContent="center" alignItems="center" height="100%" marginTop='10%'>
                        <Box>
                            < img src={NotFoundLogo} style={{ width: '50%', height: '50%' }} />
                        </Box>
                        <Box margin="auto" style={{ width: '60%' }} textAlign="center">
                            <Typography variant="body" display="block" gutterBottom style={{ lineHeight: '1.2', fontWeight: '800',color: '#888' }}>
                                找不到頁面，請返回首頁或稍後再試。
                            </Typography>
                            <Box marginTop={3} >
                                <Link to='/'>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        sx={{ borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '100px', color: 'red', fontWeight: 'bold' }}
                                    >
                                        返回首頁
                                    </Button>
                                </Link>

                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default NotFoundPage
