import {
    Box,
    Grid,
    Button,
    Paper,
    FormControl,
    MenuItem, FormHelperText
} from "@mui/material";
import TextField from '@mui/material/TextField';
import TopBar from "../../components/topBar/TopBar";
import styled from "styled-components";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import SendRequest from "../../request/SendRequest";

const phonePrefix = [
    {
        value: '852',
        label: '+852'
    },
    {
        value: '853',
        label: '+853'
    },
    {
        value: '86',
        label: '+86'
    }
]

const userTitle = [
    {
        value: 'mr',
        label: '先生'
    },
    {
        value: 'ms',
        label: '女士'
    },
    {
        value: 'miss',
        label: '小姐'
    },
    {
        value: 'other',
        label: '不透露'
    },
]

const StyledDiv = styled.div`
      margin-top: 5%;
      font-weight: bold;
      font-size: larger;
      display: flex;
      flex-direction: row;
      align-content: center;
    `;
const StyledDiv2 = styled.div`
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-content: center;
    `;


function NewUser() {

    const navigate = useNavigate()
    const [selectEnquiryPhonPrefix,setSelectEnquiryPhonePrefix] = useState('852')
    const [responseError,setResponseError] = useState(false)

    const [enquiryValues, setEnquiryValues] = useState({
        title: '',
        name: '',
        email: '',
        mobileNo: '',
    });
    const [enquiryErrors, setEnquiryErrors] = useState({
        title: false,
        name: false,
        email: false,
        mobileNo: false,
    });
    const [enquiryMessage,setEnquiryErrorMessage] = useState({
        title: '',
        name: '',
        email: '',
        mobileNo: '',
    })

    const [errorResponse,setErrorResponse] = useState('')

    const submitEnquiry = () => {
        let hasErrors = false;

        const updatedErrors = {};
        const errorMessage = {};
        const enquiryData = {
            title: enquiryValues.title,
            name: enquiryValues.name,
            country : selectEnquiryPhonPrefix,
            mobileNo : selectEnquiryPhonPrefix + enquiryValues.mobileNo,
            email: enquiryValues.email,
            type: "new_user",
            message: 'New User'
        }
        Object.entries(enquiryValues).forEach(([key, value]) => {
            if (!value.trim()) {
                updatedErrors[key] = true;
                errorMessage[key] = '此位置不能為空';
                hasErrors = true;
                setEnquiryErrors(updatedErrors);
                setEnquiryErrorMessage(errorMessage);
            }
            else {
                if (key === 'mobileNo' && enquiryData.country== '852'){
                    if (!/^\d{8}$/.test(enquiryValues.mobileNo)) {
                        updatedErrors[key] = true;
                        errorMessage[key] = '請輸入香港(852)電話號碼';
                        hasErrors = true;
                        setEnquiryErrors(updatedErrors);
                        setEnquiryErrorMessage(errorMessage);
                        }
                }
                else if (key === 'mobileNo' && enquiryData.country== '853'){
                    if (!/^\d{8}$/.test(enquiryValues.mobileNo)) {
                        updatedErrors[key] = true;
                        errorMessage[key] = '請輸入澳門(853)電話號碼';
                        hasErrors = true;
                        setEnquiryErrors(updatedErrors);
                        setEnquiryErrorMessage(errorMessage);
                        }
                }
                else if (key === 'mobileNo' && enquiryData.country== '86'){
                    if (!/^\d{11}$/.test(enquiryValues.mobileNo)) {
                        updatedErrors[key] = true;
                        errorMessage[key] = '請輸入中國(86)電話號碼';
                        hasErrors = true;
                        setEnquiryErrors(updatedErrors);
                        setEnquiryErrorMessage(errorMessage);
                        }
                }
                else{
                    updatedErrors[key] = false;
                }
            }         
        });
        if (!hasErrors) {
            SendRequest.post({url: process.env.REACT_APP_API_URL + 'subscriber/enquiry',data: enquiryData}).then(response => {
                if(response.status === 200){
                    navigate('/guest-enquiry-success')
                }
            }).catch(err => {
                if (err.response.data.errors) { // check if formated error, classification if possible
                    Object.keys(err.response.data.errors).forEach((key, value) => {
                        updatedErrors[key] = true;
                        errorMessage[key] = err.response.data.message;
                    })
                    setEnquiryErrors(updatedErrors);
                    setEnquiryErrorMessage(errorMessage);
                } else {
                    setEnquiryErrors(updatedErrors);
                    setEnquiryErrorMessage(errorMessage);
                    setResponseError(true);
                    setErrorResponse(err.response.data.message);
                }
            })
        }
    }

    const handleEnquiryValues = (event) => {
        const { name, value } = event.target;
        setEnquiryValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    const handleEnquiryPhonePrefix = (event) => {
        setSelectEnquiryPhonePrefix(event.target.value)
    }

    return(
        <Grid>
            <TopBar/>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <StyledDiv>
                        帳戶登記
                    </StyledDiv>

                </Grid>
            </Grid>
            <Grid marginTop={3} textAlign="center" container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <Box marginTop={3} marginBottom={5}>
                        <Paper style={{ padding: '5%'}}>
                            <Box margin='auto'>
                                <StyledDiv2>
                                    請輸入以下資料，我們會協助您開設帳戶。
                                </StyledDiv2>
                                <Box display="flex" justifyContent="space-between">
                                    <FormControl  sx={{  width: '25%' }} variant="filled">
                                        <TextField
                                            id="filled-basic"
                                            onChange={handleEnquiryValues}
                                            select
                                            defaultValue="title"
                                            name="title"
                                            margin='normal'
                                            error={enquiryErrors.title}
                                            helperText={enquiryMessage.title ? "此位置不能為空" : ""}
                                            variant="filled" >
                                            <MenuItem disabled key="" value="title">
                                                稱謂
                                            </MenuItem>
                                            {
                                                userTitle.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </FormControl>
                                    <TextField
                                        name='name'
                                        id="filled-basic"
                                        value={enquiryValues.name}
                                        onChange={handleEnquiryValues}
                                        error={enquiryErrors.name}
                                        helperText={enquiryMessage.name ? "此位置不能為空" : ""}
                                        label='名稱'
                                        margin='normal'
                                        style={{ width: '70%' }}
                                        variant="filled" />
                                </Box>
                                <Box display="flex" justifyContent="space-between">
                                    <TextField
                                        id="filled-basic"
                                        defaultValue='852'
                                        onChange={handleEnquiryPhonePrefix}
                                        select
                                        margin='normal'
                                        style={{ width: '25%' }}
                                        variant="filled" >
                                        {
                                            phonePrefix.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    <TextField
                                        name='mobileNo'
                                        id="filled-basic"
                                        value={enquiryValues.mobileNo}
                                        onChange={handleEnquiryValues}
                                        error={enquiryErrors.mobileNo}
                                        helperText={enquiryMessage.mobileNo ? enquiryMessage.mobileNo : ""}
                                        label='輸入聯絡號碼'
                                        margin='normal'
                                        style={{ width: '70%' }}
                                        variant="filled" />
                                </Box>
                                <TextField
                                        name='email'
                                        id="filled-basic"
                                        value={enquiryValues.email}
                                        onChange={handleEnquiryValues}
                                        error={enquiryErrors.email}
                                        helperText={enquiryMessage.email}
                                        label='輸入電郵地址'
                                        margin='normal'
                                        style={{ width: '100%' }}
                                        variant="filled" 
                                        />
                                        {
                                            responseError &&
                                            <FormHelperText style={{ color: '#FF395D'}} id="outlined-weight-helper-text">{errorResponse}</FormHelperText>
                                        }
                            </Box>
                            <Box marginTop={3} display="flex" justifyContent="center">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    sx={{ borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '100px', color: 'red', fontWeight: 'bold' }}
                                    onClick={() => submitEnquiry()}
                                >
                                    發送
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    )
}
export default NewUser
