import EmptyCardBg from "../../assets/desc_card_bg.png";
import Icon from "../Icon/Icon";
import Card from "../Card/Card";
import InfoCardBg from "../../assets/info_card_bg.png";
import InfoCardPortrait from "./info_card_portrait.svg";
import InfoCardPortraitEmpty from "./info_card_portrait_empty.svg";
import InfoHelpModal from "./InfoHelpModal";
import styles from "./InfoCard.module.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const InfoCard = ({ data, fullWidth = false, infoHelpData }) => {
    const { t } = useTranslation();
    const { name, title, gender, content } = data;

    const [showInfoHelp, setShowInfoHelp] = useState(false);
    
    return (
        <>
            <Card fullWidth={fullWidth} >
                <div className={styles["card-heading"]}>
                    <div className={styles["user-info"]}>
                        <Icon name="user" className={styles["user-icon"] + " " + (gender === 'M' ? styles["user-icon--male"] : styles["user-icon--female"])} />
                        <div className={styles["name"]}>
                            {name}
                        </div>
                    </div>
                    <div className={styles["info-explanation"]} onClick={() => setShowInfoHelp(true)}>{t("info_explanation")}</div>
                    {/* <Icon name="question" className={styles["question-icon"]} onClick={() => setShowInfoHelp(true)} /> */}
                </div>
                <div className={styles["card-content"]}>
                    <div className={styles["content-container"] + (fullWidth ? " " + styles["content-container--full-width"] : '')}>
                        <div style={{ width:"100%", paddingBottom:"15px"}}>
                            {
                                content?.length > 0 ?
                                <div className={styles["content-title"]}>
                                    {title}
                                </div> :
                                <div className={styles["content-title"] + " " + styles["content-title-empty"]}>
                                    {title}
                                </div>
                            }
                            <div className={styles["content-info"] + (fullWidth ? " " + styles["content-info--full-width"] : '')}>
                                {   
                                    content?.length > 0 ? content.map((item, index) => (
                                        <div key={index} className={styles["info-row"] + (fullWidth ? " " + styles["info-row--full-width"] : '')}>
                                            {/* <p>{item.icon}</p> */}
                                            <Icon name={item.icon} className={styles["info-icon"]} />
                                            <div className={styles["info-label"]}>
                                                {item.label}
                                            </div>
                                            <div className={styles["info-count"]}>
                                                {item.count_label}
                                            </div>
                                        </div>
                                    )) : null
                                }
                            </div>
                        </div>
                        <div style={{ display:"flex" }}>
                            {
                                content?.length > 0 ? <img src={InfoCardPortrait} className={styles["content-portrait"]} alt="Card bg" /> : <img src={InfoCardPortraitEmpty} className={styles["content-portrait-empty"]} alt="Card bg" />
                            }
                        </div>
                    </div>
                </div>
            </Card>
            <InfoHelpModal infoHelpData={infoHelpData} show={showInfoHelp} onClose={() => setShowInfoHelp(false)} />
        </>
    );
};

export default InfoCard;