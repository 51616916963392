import { useEffect, useState } from "react";
import { InfoCard, Tabs, HistoryCard } from "../../components";
import { useNavigate } from "react-router-dom";
import SendRequest from "../../request/SendRequest";
import { Box, Grid } from "@mui/material";
import TopBar from "../../components/topBar/TopBar";
import Loading from "../../components/Loading/loading";

const OverallReport = () => {

    const navigate = useNavigate();


    const [tabValue, setTabValue] = useState(0);
    const [pageTitle, setPageTitle] = useState('');
    const [data, setData] = useState([{
        id: "",
        name: "",
        gender: "",
        title: "",
        content: "",
        historyData: [{
            year: '',
            data: [
                {
                    key: '',
                    label: ''
                }
            ]
        }]
    }]);
    const [infoHelpData, setInfoHelpData] = useState([{
        icon: '',
        title: '',
        description: '',
    }]);
    const [tabs, setTabs] = useState([{
        key: "",
        label: "",
    }]);
    const [loading,setLoading] = useState(true)

    const handleHistoryClick = (item) => {
        navigate(`/report/detail/${data[tabValue].id}/${item.key}`, {
        state: {
            infoHelpData
        },
        });
    }


    useEffect(() => {
        fetchContent();
    }, []);


    const fetchContent = async () => {
        const activity = await SendRequest.post({ url: process.env.REACT_APP_API_URL + 'subscription/activity' }).then(response => {
            let data = response.data.data.map((data, key) => (
                {
                    id: data.id,
                    name: data.name,
                    gender: data.gender,
                    title: data.content_title,
                    content: data.content,
                    historyData: data.history.map((history, key) => (
                        {
                            year: history.year,
                            data: history.data,
                        }
                    ))
                }
            ));
            setData(data);
            let infoHelpData = response.data.data[0].infoHelp.map((data, key) => (
                {
                    icon: data.icon,
                    title: data.label,
                    description: data.description,
                }
            ));
            setInfoHelpData(infoHelpData);
            let tabs = response.data.data.map((data, key) => (
                {
                    key: key,
                    label: data.name,
                }
            ));
            setTabs(tabs);
        }).catch(err => {
            console.log(err);
            navigate('/404')
        })
        const settings = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'settings/all' }).then(response => {
            if (response.status === 200) {
                setPageTitle(response.data.data.report_detail_index_title);
            }
        })
        Promise.all([activity, settings]).then(() => {
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    };


    return (
        <Grid>
            <Loading show={loading} />
            <TopBar />
            <Box style={{
                maxWidth: '750px',
                margin: 'auto',
            }}>
                <Grid container className="index-header" maxWidth="750px" margin="auto">
                    <Grid item xs={12} sm={12} >
                        <Box className="page-title" fontSize={18} fontWeight={500}>{pageTitle}</Box>
                    </Grid>
                </Grid>
                <div style={{ paddingLeft:"15px",paddingRight:"15px" }}>
                    <Tabs options={tabs} value={tabValue} onClick={(value) => setTabValue(value)} />
                    <br></br>
                    <br></br>
                    {
                        <InfoCard data={data[tabValue]} infoHelpData={infoHelpData} fullWidth="true"/>
                    }
                    <br></br>
                    <Box textAlign='start' fontWeight={500} display='flex' style={{ paddingBottom: '10px' }}>
                        <Box>
                            過往記錄
                        </Box>
                        <div style={{ flexGrow: 1 }}></div>
                    </Box>
                    <HistoryCard data={data[tabValue].historyData} infoHelpData={infoHelpData} onClick={(item) => handleHistoryClick(item)} />
                </div>
            </Box>
        </Grid>
    )
}

export default OverallReport;