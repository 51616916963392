import Card from "./Card/Card";
import DetailRecordCard from "./ReportCard/DetailRecordCard";
import HistoryCard from "./ReportCard/HistoryCard";
import HtmlContent from "./HtmlContent/HtmlContent";
import Icon from "./Icon/Icon";
import InfoCard from "./ReportCard/InfoCard";
import Modal from "./Modal/Modal";
import Tabs from "./Tabs/Tabs";
import YouTube from "./YouTube/Youtube";
import ReactGA from 'react-ga';


const enableGA = Boolean(process.env.REACT_APP_ENABLE_GA);
const GAcode = (process.env.GA_CODE);

if( enableGA === true){
    ReactGA.initialize(GAcode);
    console.log("GA Enable");
}


export {
    Card,
    DetailRecordCard,
    HistoryCard,
    HtmlContent,
    Icon,
    InfoCard,
    Modal,
    Tabs,
    YouTube,
}