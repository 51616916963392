
import Icon from "../Icon/Icon";
import Card from "../Card/Card";
import styles from "./UserProfileInfo.module.scss";
import moment from "moment/moment";

const UserProfileInfo = ({ data }) => {
    const { name, gender, subscribed_service_detail, age, subscription_count, user_count } = data;

    return (
        <>
            <div className={styles["subscriber-info"]}>
                <Card>
                    <div className={styles["card-heading"]}>
                        <div className={styles["user-info"]}>
                            <Icon name="user" className={styles["user-icon"] + " " + (gender === 'M' ? styles["user-icon--male"] : styles["user-icon--female"])} />
                            <div className={styles["name"]}>
                                {name}<span className="age">({age}歲)</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles["card-content"]}>
                        <div className={styles["content-container"]}>
                            {
                                subscribed_service_detail.map((data, key) => (
                                    <div>
                                        {/* <div className={styles["data-row"]}><div className={styles["data-key"]}>訂閱地址</div><div className={styles["data-value"]}>{data.install_address}</div></div> */}
                                        <div className={styles["data-row"]} ><div className={styles["data-key"]}>已訂閱服務</div><div className={styles["data-value"]}>{data.svc_plan_desc}</div></div>
                                        <div className={styles["data-row"]}><div className={styles["data-key"]}>訂閱合約至</div><div className={styles["data-value"]}>{moment(data.svc_subsribe_agree_period).format('Y年M月D日')}</div></div>
                                        {/* <div className={styles["data-row"]}><div className={styles["data-key"]}>價格(月)</div><div className={styles["data-value"]}>$ {data.price}</div></div> */}
                                        {key === 0 ? '' : <hr />}
                                    </div>
                                ))
                            }
                            {/* <div className={styles["data-row"]}><div className={styles["data-key"]}>聯絡號碼</div><div className={styles["data-value"]}>{phone_no}</div></div> */}

                        </div>
                    </div>
                </Card >
            </div >
        </>
    );
};

export default UserProfileInfo;