import Icon from "../Icon/Icon";
import Modal from "../Modal/Modal";
import styles from "./Youtube.module.scss"
import { useState, useEffect, useRef } from "react";


const YouTube = (props) => {
    const { link } = props
    
    // Generate a unique id for the player
    const playerId = "ytplayer" + link.split("v=")[1];
    
    const imgRef = useRef(null);

    const playerThumbnail = `https://img.youtube.com/vi/${link.split("v=")[1]}/maxresdefault.jpg`;
    
    const handleImageLoad = () => {
        var thumbnail = ["maxresdefault", "hqdefault", "mqdefault", "sddefault", "default"];
        var url = imgRef.current.attributes.src.value;
        if (imgRef.current.attributes[0]['ownerElement']['naturalWidth'] === 120 && imgRef.current.attributes[0]['ownerElement']['naturalHeight'] === 90) {
            for (var i = 0, len = thumbnail.length - 1; i < len; i++) {
                if (url.indexOf(thumbnail[i]) > 0) {
                    imgRef.current.attributes[0]['value'] = url.replace(thumbnail[i], thumbnail[i + 1]);
                    break;
                }
            }
        }
    }
    //  State to show/hide the play button
    const [showPlayer, setShowPlayer] = useState(false);
    
    return (
        <>
            <div className={styles["youtube-container"]}>
                <div className={styles["youtube-thumbnail"]} onClick={() => setShowPlayer(true)}>
                    <img ref={imgRef} onLoad={handleImageLoad} src={playerThumbnail} alt="YouTube video thumbnail" />
                    <div className={styles["play-btn"]}>
                        <Icon name="play-circle" />
                    </div>
                </div>
            </div>
            <Modal show={showPlayer} overlayClose={false}>
                <Icon className={styles["cross-icon"]} name="cross" onClick={() => setShowPlayer(false)} />
                <div className={styles["youtube-modal"]}>
                    <iframe
                        id={playerId}
                        className={styles["youtube-iframe"]}
                        width="560"
                        height="315"
                        src={link.replace("watch?v=", "embed/")}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </Modal>
        </>
    );
    };

export default YouTube;