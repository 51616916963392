import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import "@fontsource/noto-sans-tc";
import "@fontsource/noto-sans-tc/500.css";
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import translationTc from "./locales/tc/translation.json";

const root = ReactDOM.createRoot(document.getElementById('root'))

const theme = createTheme({
  typography: {
    fontFamily: `"Noto Sans TC", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
});

  root.render(
    <React.StrictMode>
      <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  )

  const resources = {
    tc: {
      translation: translationTc
    }
  };

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'tc',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources
  });


export default i18n;
