import './App.scss';
import { useRoutes, useLocation} from "react-router-dom";
import routes from "./router/router";
import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module'

function App() {

    const [cookies, setCookie] = useCookies(['subscriber_id']);
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_CODE,
        dataLayer: {
            userId: cookies.subscriber_id,
        },
        events: {
            pageview: window.location.pathname
        }
    }
    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
    }, [])

    return (
        <div className="App">
            <div className='content'>
                {/*<PrivateRoute element={routing} />*/}
                {useRoutes(routes)}
            </div>
        </div>

    );
}

export default App;