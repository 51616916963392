import styles from "./Card.module.scss";

const Card = ({ children, fullWidth = false, ...attrs }) => {
    return (
        <>
            <div className={styles["card"]} {...attrs}>
                <div className={styles["card-wrapper"] + (fullWidth ? " " + styles["card-wrapper--full-width"] : '')}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Card;