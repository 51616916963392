import axios from "axios";
import { Cookies } from 'react-cookie';


class SendRequest {

    constructor(baseURL, timeout = 30000) {
        this.instance = axios.create({
            baseURL,
            timeout,
        })
        this.instance.interceptors.request.use((config) => {
            const cookies = new Cookies();
            const token = cookies.get('subscriber_token', {path: "/"});
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }
            return config;
        }, (error) => {
            return Promise.reject(error);
        });
    }

    request(config) {
        const cookies = new Cookies();

        return new Promise((resolve, reject) => {
            this.instance.request(config).then(res => {
                resolve(res)
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    cookies.remove('subscriber_token', {path: "/"});
                    cookies.remove('subscriber_id', { path: '/' });

                    // window.location.href = '/'
                }
                reject(error);
            })
        })
    }

    get(config) {
        return this.request(
            {
                ...config,
                method: "get"
            }
        )
    }

    post(config) {
        return this.request(
            {
                ...config,
                method: "post"
            }
        )
    }

    delete(config) {
        return this.request(
            {
                ...config,
                method: "delete"
            }
        )
    }

    patch(config) {
        return this.request(
            {
                ...config,
                method: "patch"
            }
        )
    }






}

export default new SendRequest()




