import {
    Box,
    Grid,
    Button,
    Paper,
    FormControl,
    InputLabel,
    Typography, FilledInput, InputAdornment, MenuItem, FormHelperText
} from "@mui/material";
import TextField from '@mui/material/TextField';
import TopBar from "../../components/topBar/TopBar";
import styled from "styled-components";
import React, {useState} from "react";
import WaringLogo from '../../assets/waring_icon.png'
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import SendRequest from "../../request/SendRequest";

const phonePrefix = [
    {
        value: '852',
        label: '+852'
    },
    {
        value: '853',
        label: '+853'
    },
    {
        value: '86',
        label: '+86'
    }
]

const userTitle = [
    {
        value: 'mr',
        label: '先生'
    },
    {
        value: 'ms',
        label: '女士'
    },
    {
        value: 'miss',
        label: '小姐'
    },
    {
        value: 'other',
        label: '不透露'
    },
]

const StyledDiv = styled.div`
      margin-top: 5%;
      font-weight: bold;
      font-size: larger;
      display: flex;
      flex-direction: row;
      align-content: center;
    `;
const StyledDiv2 = styled.div`
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-content: center;
    `;


function Register() {

    const navigate = useNavigate()
    const [disabled,setDisabled] = useState(false)
    const [inputLabel,setInputLabel] = useState("輸入合約登記電話號碼")
    const [inputlabel2,setInputLabel2] = useState("輸入合約編號")
    const [titlelabel,setTitleLabel] = useState("填寫以下資料")
    const [showNext,setShowNext] = useState(false)
    const [showEnquiry,setShowEnquiry] = useState(false)
    const [disableNext,setDisableNext] = useState(false)
    const [error,setError] = useState(false)
    const [selectPhonPrefix,setSelectPhonePrefix] = useState('852')
    const [selectEnquiryPhonPrefix,setSelectEnquiryPhonePrefix] = useState('852')
    const [responseError,setResponseError] = useState(false)
    const [userErrorCount,setUserErrorCount] = useState(0)
    const [contractValues, setContractValues] = useState({
        phoneNo: '',
        contractNo: ''
    });
    const [errors, setErrors] = useState({
        phoneNo: false,
        contractNo: false
    });
    const [errorMessage,setErrorMessage] = useState({
        phoneNo: '',
        contractNo: ''
    })
    const [userValues, setUserValues] = useState({
        mobileNo: '',
        username: '',
        password: '',
        rePassword: ''
    });
    const [userErrors, setUserErrors] = useState({
        mobileNo: false,
        username: false,
        password: false,
        rePassword: false
    });
    const [userErrorMessage,setUserErrorMessage] = useState({
        mobileNo: '',
        username: '',
        password: '',
        rePassword: ''
    })
    const [enquiryValues, setEnquiryValues] = useState({
        title: '',
        name: '',
        email: '',
        mobileNo: '',
    });
    const [enquiryErrors, setEnquiryErrors] = useState({
        title: false,
        name: false,
        email: false,
        mobileNo: false,
    });
    const [enquiryMessage,setEnquiryErrorMessage] = useState({
        title: '',
        name: '',
        email: '',
        mobileNo: '',
    })

    const [errorResponse,setErrorResponse] = useState('')


    const nextRegister = () => {

        const preRegisterUser = {
            phoneNo: contractValues.phoneNo,
            contractNo: contractValues.contractNo,
        }

        setDisableNext(true);
        let hasErrors = false;
        const updatedErrors = {};
        const errorMessage = {};

        Object.entries(contractValues).forEach(([key, value]) => {
            if (!value.trim()) {
                updatedErrors[key] = true;
                errorMessage[key] = '此位置不能為空';
                hasErrors = true;
                setErrors(updatedErrors);
                setErrorMessage(errorMessage);
                setDisableNext(false);
            } else {
                setUserErrorCount(0);
                updatedErrors[key] = false;
            }
        });
        if (!hasErrors) {
            SendRequest.post({url: process.env.REACT_APP_API_URL + 'subscriber/preRegister',data: preRegisterUser}).then(response => {
                setDisabled(true)
                setErrors(updatedErrors);
                setErrorMessage(errorMessage);
                setUserErrorCount(0);
                setInputLabel('合約登記電話號碼')
                setInputLabel2('合約編號')
                setTitleLabel('確認以下資料')
                setShowNext(true)
                setDisableNext(false);
            }).catch(err => {
                updatedErrors['phoneNo'] = true;
                updatedErrors['contractNo'] = true;
                setErrors(updatedErrors);
                errorMessage['contractNo'] = err.response.data.message;
                setErrorMessage(errorMessage);
                setUserErrorCount(userErrorCount+1);
                setDisableNext(false);
                if (userErrorCount >= 4) {
                    setDisabled(true)
                    errorMessage['phoneNo'] = '';
                    errorMessage['contractNo'] = '';
                    setErrorMessage(errorMessage);
                }
            })
        }
    }

    const retryVervifyRegister = () => {
        const updatedErrors = {};
        setUserErrorCount(0);
        setDisabled(false)
        updatedErrors['phoneNo'] = false;
        updatedErrors['contractNo'] = false;
        setErrors(updatedErrors);
    }

    const nextEnquiry = () => {
        const updatedErrors = {};
        const errorMessage = {};
        setDisabled(true)
        setErrors(updatedErrors);
        setErrorMessage(errorMessage);
        setUserErrorCount(0);
        setInputLabel('合約登記電話號碼')
        setInputLabel2('合約編號')
        setTitleLabel('剛才已輸入的資料')
        setShowEnquiry(true)
        setDisableNext(false);
    }

    const submitUser = () => {
        let hasErrors = false;

        const updatedErrors = {};
        const errorMessage = {};
        Object.entries(userValues).forEach(([key, value]) => {
            if (!value.trim()) {
                updatedErrors[key] = true;
                errorMessage[key] = '此位置不能為空';
                hasErrors = true;
                setUserErrors(updatedErrors);
                setUserErrorMessage(errorMessage);
                setError(true);
            } else {
                updatedErrors[key] = false;
                errorMessage[key] = false;
                setError(false);
            }
            if(key === 'rePassword'){
                if(value !== userValues.password.toString()){
                    updatedErrors['password'] = true;
                    updatedErrors['rePassword'] = true;
                    errorMessage['password'] = '';
                    errorMessage['rePassword'] = '兩次輸入的密碼必須相同';
                    hasErrors = true;
                    setUserErrors(updatedErrors);
                    setUserErrorMessage(errorMessage);
                    setError(userValues.rePassword !== userValues.password)
                }
            }
        });

        if(!hasErrors){
            const registerUser = {
                phoneNo: contractValues.phoneNo,
                contractNo: contractValues.contractNo,
                mobileNo: selectPhonPrefix + userValues.mobileNo,
                username: userValues.username,
                password: userValues.password
            }
            SendRequest.post({url: process.env.REACT_APP_API_URL + 'subscriber/registerVerify',data: registerUser}).then(response => {
            }).catch(err => {
                if (err.response.data.errors) {
                    Object.keys(err.response.data.errors).forEach((key, value) => {
                        updatedErrors[key] = true;
                        errorMessage[key] = err.response.data.message;
                    })
                    setUserErrors(updatedErrors);
                    setUserErrorMessage(errorMessage);
                    setError(true);
                } 
            })

           handleRegister();
        }

    }

    const submitEnquiry = () => {
        let hasErrors = false;

        const updatedErrors = {};
        const errorMessage = {};
        const enquiryData = {
            title: enquiryValues.title,
            name: enquiryValues.name,
            country : selectEnquiryPhonPrefix,
            mobileNo : selectEnquiryPhonPrefix + enquiryValues.mobileNo,
            email: enquiryValues.email,
            type: "forgot_account",
            message: "Inputed Information : \nPhone No : " + contractValues.phoneNo +'\nContract No : ' + contractValues.contractNo,
        }
        Object.entries(enquiryValues).forEach(([key, value]) => {
            if (!value.trim()) {
                updatedErrors[key] = true;
                errorMessage[key] = '此位置不能為空';
                hasErrors = true;
                setEnquiryErrors(updatedErrors);
                setEnquiryErrorMessage(errorMessage);
            }
            else {
                if (key === 'mobileNo' && enquiryData.country== '852'){
                    if (!/^\d{8}$/.test(enquiryValues.mobileNo)) {
                        updatedErrors[key] = true;
                        errorMessage[key] = '請輸入香港(852)電話號碼';
                        hasErrors = true;
                        setEnquiryErrors(updatedErrors);
                        setEnquiryErrorMessage(errorMessage);
                        }
                }
                else if (key === 'mobileNo' && enquiryData.country== '853'){
                    if (!/^\d{8}$/.test(enquiryValues.mobileNo)) {
                        updatedErrors[key] = true;
                        errorMessage[key] = '請輸入澳門(853)電話號碼';
                        hasErrors = true;
                        setEnquiryErrors(updatedErrors);
                        setEnquiryErrorMessage(errorMessage);
                        }
                }
                else if (key === 'mobileNo' && enquiryData.country== '86'){
                    if (!/^\d{11}$/.test(enquiryValues.mobileNo)) {
                        updatedErrors[key] = true;
                        errorMessage[key] = '請輸入中國(86)電話號碼';
                        hasErrors = true;
                        setEnquiryErrors(updatedErrors);
                        setEnquiryErrorMessage(errorMessage);
                        }
                }
                else{
                    updatedErrors[key] = false;
                }
            }         
        });
        if (!hasErrors) {
            SendRequest.post({url: process.env.REACT_APP_API_URL + 'subscriber/enquiry',data: enquiryData}).then(response => {
                if(response.status === 200){
                    navigate('/guest-enquiry-success')
                }
            }).catch(err => {
                if (err.response.data.errors) { // check if formated error, classification if possible
                    Object.keys(err.response.data.errors).forEach((key, value) => {
                        updatedErrors[key] = true;
                        errorMessage[key] = err.response.data.message;
                    })
                    setEnquiryErrors(updatedErrors);
                    setEnquiryErrorMessage(errorMessage);
                } else {
                    setEnquiryErrors(updatedErrors);
                    setEnquiryErrorMessage(errorMessage);
                    setResponseError(true);
                    setErrorResponse(err.response.data.message);
                }
            })
        }
    }

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleContractPhoneNo = (event) => {
        const { name, value } = event.target;
        setContractValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    const handleEnquiryValues = (event) => {
        const { name, value } = event.target;
        setEnquiryValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    const handleUserValues = (event) => {
        const { name, value } = event.target;
        setUserValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    const handleRegister = () => {
        const registerUser = {
            phoneNo: contractValues.phoneNo,
            contractNo: contractValues.contractNo,
            mobileNo: selectPhonPrefix + userValues.mobileNo,
            username: userValues.username,
            password: userValues.password
        }
        const updatedErrors = {};
        const errorMessage = {};

        SendRequest.post({url: process.env.REACT_APP_API_URL + 'subscriber/registerVerify',data: registerUser}).then(response => {
            if(response.status === 200){
                const sendUser = {
                    phoneNo: contractValues.phoneNo,
                    contractNo:contractValues.contractNo,
                    mobileNo: selectPhonPrefix + userValues.mobileNo,
                }
                SendRequest.post({url:process.env.REACT_APP_API_URL + 'subscriber/sendOtpMessage',data: sendUser}).then(response => {
                    if(response.status === 200){
                        const userInfo = {
                            phoneNo: contractValues.phoneNo,
                            contractNo: contractValues.contractNo,
                            mobileNo: selectPhonPrefix + userValues.mobileNo,
                            username: userValues.username,
                            password: userValues.password
                        }
                        navigate('/sms',{state: userInfo})
                    }else {
                        setResponseError(true)
                    }
                }).catch(err => {
                    setErrorResponse(err.response.data.message)
                    setResponseError(true)
                })
            }else {
            }
        }).catch(err => {
            if (err.response.data.errors) {
                Object.keys(err.response.data.errors).forEach((key, value) => {
                    updatedErrors[key] = true;
                    errorMessage[key] = err.response.data.message;
                })
                setUserErrors(updatedErrors);
                setUserErrorMessage(errorMessage);
            } else {
                setUserErrors(updatedErrors);
                setUserErrorMessage(errorMessage);
                setResponseError(true);
                setErrorResponse(err.response.data.message);
            }
        })
    }

    const handleBack = () => {
        setDisabled(false)
        setInputLabel('輸入合約登記電話號碼')
        setInputLabel2('輸入合約編號')
        setTitleLabel('填寫以下資料')
        setShowNext(false)
        // window.location.reload()
    }

    const handlePhonePrefix = (event) => {
        setSelectPhonePrefix(event.target.value)
    }

    const handleEnquiryPhonePrefix = (event) => {
        setSelectEnquiryPhonePrefix(event.target.value)
    }

    return(
        <Grid>
            <TopBar/>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <StyledDiv>
                        帳戶登記
                    </StyledDiv>

                </Grid>
            </Grid>
            <Grid marginTop={3} textAlign="center" container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <Box>
                        <Paper style={{ padding: '5%'}}>
                            <Box margin='auto'>
                                <StyledDiv2>
                                    {titlelabel}
                                </StyledDiv2>
                                <TextField
                                    name='phoneNo'
                                    disabled={disabled}
                                    error={errors.phoneNo}
                                    id="filled-basic"
                                    value={contractValues.phoneNo}
                                    onChange={handleContractPhoneNo}
                                    label={inputLabel}
                                    margin='normal'
                                    helperText={errors.phoneNo ? errorMessage.phoneNo : ""}
                                    style={{ width: '100%' }}
                                    variant="filled" />
                                <TextField
                                    name='contractNo'
                                    disabled={disabled}
                                    error={errors.contractNo}
                                    id="filled-basic"
                                    value={contractValues.contractNo}
                                    onChange={handleContractPhoneNo}
                                    label={inputlabel2}
                                    margin='normal'
                                    helperText={errors.contractNo ? errorMessage.contractNo : ""}
                                    style={{ width: '100%' }}
                                    variant="filled" />
                                <Box display='flex' alignItems='flex-start' marginTop={2} style={{ display: (userErrorCount > 4) ? 'flex' : 'none'}}>
                                    <img src={WaringLogo} alt='logo' style={{width: '4%',height: '4%',verticalAlign: 'top', marginRight: '8px'}}/>
                                    <Typography variant="body2" display="block" gutterBottom style={{ textAlign: 'left',color: 'red',lineHeight:'1.2' }}>
                                        請輸入香港(852)/澳門(853)/中國(86)電話號碼。
                                    </Typography>
                                </Box>

                            </Box>
                            <Box marginTop={3} >
                                <Button
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        href="new-user"
                                        sx={{ marginRight: '15px', borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '150px', color: 'red', fontWeight: 'bold', display: disabled ? "none" : "inline-flex" }}
                                >
                                    忘記合約登記資料
                                </Button>
                                <Button
                                    disabled={disableNext}
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    sx={{ marginLeft: '15px', borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '100px', color: 'red', fontWeight: 'bold', display: disabled ? "none" : "inline-flex" }}
                                    onClick={() => nextRegister()}
                                >
                                    下一步
                                </Button>
                            </Box>
                            <Box marginTop={3} >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    sx={{ marginRight: '15px', borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '200px', color: 'red', fontWeight: 'bold', display: (userErrorCount > 4) ? 'inline-flex' : 'none' }}
                                    onClick={() => nextEnquiry()}
                                >
                                    忘記合約登記資料
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    sx={{ marginLeft: '15px', borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '100px', color: 'red', fontWeight: 'bold', display: (userErrorCount > 4) ? 'inline-flex' : 'none' }}
                                    onClick={() => retryVervifyRegister()}
                                >
                                    重新輸入
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                    <Box marginTop={3} marginBottom={5} style={{ display: showNext ? 'block' : 'none'}}>
                        <Paper style={{ padding: '5%'}}>
                            <Box margin='auto'>
                                <StyledDiv2>
                                    填寫以下資料
                                </StyledDiv2>
                                <Box display="flex" justifyContent="space-between">
                                    <TextField
                                        id="filled-basic"
                                        defaultValue='852'
                                        onChange={handlePhonePrefix}
                                        select
                                        margin='normal'
                                        style={{ width: '25%' }}
                                        variant="filled" >
                                        {
                                            phonePrefix.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    <TextField
                                        name='mobileNo'
                                        id="filled-basic"
                                        value={userValues.mobileNo}
                                        onChange={handleUserValues}
                                        error={userErrors.mobileNo}
                                        helperText={userErrors.mobileNo ? userErrorMessage.mobileNo : ""}
                                        label='輸入聯絡號碼'
                                        margin='normal'
                                        style={{ width: '70%' }}
                                        variant="filled" />
                                </Box>

                                <TextField
                                    name='username'
                                    id="filled-basic"
                                    value={userValues.username}
                                    onChange={handleUserValues}
                                    error={userErrors.username}
                                    helperText={userErrors.username ? userErrorMessage.username : ""}
                                    label='建立用戶名稱'
                                    margin='normal'
                                    style={{ width: '100%'}}
                                    variant="filled" />

                                <FormControl error={userErrors.password} sx={{  width: '100%' }} variant="filled">
                                    <InputLabel htmlFor="outlined-adornment-password" >建立新密碼</InputLabel>
                                    <FilledInput
                                        name='password'
                                        id="outlined-adornment-password"
                                        value={userValues.password}
                                        onChange={handleUserValues}
                                        type={showPassword ? 'text' : 'password'}
                                        style={{borderColor: userErrors.password ? 'red' : undefined }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                    {
                                        userErrors.password &&
                                        <FormHelperText id="outlined-weight-helper-text">{userErrorMessage.password}</FormHelperText>
                                    }

                                </FormControl>

                                <FormControl error={userErrors.rePassword} sx={{  width: '100%', marginTop: '10px'}} variant="filled">
                                    <InputLabel htmlFor="outlined-adornment-password">重覆輸入新密碼</InputLabel>
                                    <FilledInput
                                        name='rePassword'
                                        id="outlined-adornment-password"
                                        value={userValues.rePassword}
                                        onChange={handleUserValues}
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                    {
                                        userErrors.rePassword &&
                                        <FormHelperText id="outlined-weight-helper-text">{userErrorMessage.rePassword}</FormHelperText>
                                    }
                                    {
                                        responseError &&
                                        <FormHelperText style={{ color: '#FF395D'}} id="outlined-weight-helper-text">{errorResponse}</FormHelperText>
                                    }
                                </FormControl>
                                {/*{error &&*/}
                                {/*    <Box display='flex' alignItems='flex-start' marginTop={2}>*/}
                                {/*        <Typography variant="body2" display="block" gutterBottom style={{ textAlign: 'left',color: 'red',lineHeight:'1.2' }}>*/}
                                {/*            */}
                                {/*        </Typography>*/}
                                {/*    </Box>*/}
                                {/*}*/}
                            </Box>
                            <Box marginTop={3} display="flex" justifyContent="center" style={{marginTop : '10px'}}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    sx={{ borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '100px', color: 'red', fontWeight: 'bold',marginRight:"8%" }}
                                    onClick={() => handleBack()}
                                >
                                    上一步
                                </Button>

                                {/*<Link to='/sms'>*/}
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        sx={{ borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '100px', color: 'red', fontWeight: 'bold' }}
                                        onClick={() => submitUser()}
                                    >
                                        登記
                                    </Button>
                                {/*</Link>*/}

                            </Box>
                        </Paper>
                    </Box>
                    <Box marginTop={3} marginBottom={5} style={{ display: showEnquiry ? 'block' : 'none'}}>
                        <Paper style={{ padding: '5%'}}>
                            <Box margin='auto'>
                                <StyledDiv2>
                                請輸入以下資料，我們會協助您開設賬戶。
                                </StyledDiv2>
                                <Box display="flex" justifyContent="space-between">
                                    <FormControl  sx={{  width: '25%' }} variant="filled">
                                        <TextField
                                            id="filled-basic"
                                            onChange={handleEnquiryValues}
                                            select
                                            defaultValue="title"
                                            name="title"
                                            margin='normal'
                                            error={enquiryErrors.title}
                                            helperText={enquiryMessage.title ? "此位置不能為空" : ""}
                                            variant="filled" >
                                            <MenuItem disabled key="" value="title">
                                                稱謂
                                            </MenuItem>
                                            {
                                                userTitle.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </FormControl>
                                    <TextField
                                        name='name'
                                        id="filled-basic"
                                        value={enquiryValues.name}
                                        onChange={handleEnquiryValues}
                                        error={enquiryErrors.name}
                                        helperText={enquiryMessage.name ? "此位置不能為空" : ""}
                                        label='名稱'
                                        margin='normal'
                                        style={{ width: '70%' }}
                                        variant="filled" />
                                </Box>
                                <Box display="flex" justifyContent="space-between">
                                    <TextField
                                        id="filled-basic"
                                        defaultValue='852'
                                        onChange={handleEnquiryPhonePrefix}
                                        select
                                        margin='normal'
                                        style={{ width: '25%' }}
                                        variant="filled" >
                                        {
                                            phonePrefix.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    <TextField
                                        name='mobileNo'
                                        id="filled-basic"
                                        value={enquiryValues.mobileNo}
                                        onChange={handleEnquiryValues}
                                        error={enquiryErrors.mobileNo}
                                        helperText={enquiryMessage.mobileNo ? enquiryMessage.mobileNo : ""}
                                        label='輸入聯絡號碼'
                                        margin='normal'
                                        style={{ width: '70%' }}
                                        variant="filled" />
                                </Box>
                                <TextField
                                        name='email'
                                        id="filled-basic"
                                        value={enquiryValues.email}
                                        onChange={handleEnquiryValues}
                                        error={enquiryErrors.email}
                                        helperText={enquiryMessage.email}
                                        label='輸入電郵地址'
                                        margin='normal'
                                        style={{ width: '100%' }}
                                        variant="filled" 
                                        />
                                        {
                                            responseError &&
                                            <FormHelperText style={{ color: '#FF395D'}} id="outlined-weight-helper-text">{errorResponse}</FormHelperText>
                                        }
                            </Box>
                            <Box marginTop={3} display="flex" justifyContent="center">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    sx={{ borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '100px', color: 'red', fontWeight: 'bold' }}
                                    onClick={() => submitEnquiry()}
                                >
                                    發送
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    )
}
export default Register
