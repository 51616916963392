import {
    Box,
    Grid,
    Button,
    Paper,
    FormControl,
    MenuItem,
    FormHelperText,
    InputLabel,
    FilledInput,
    InputAdornment
} from "@mui/material";
import TextField from '@mui/material/TextField';
import TopBar from "../../components/topBar/TopBar";
import styled from "styled-components";
import React, {useRef, useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import SendRequest from "../../request/SendRequest";

const StyledDiv = styled.div`
      margin-top: 5%;
      font-weight: bold;
      font-size: larger;
      display: flex;
      flex-direction: row;
      align-content: center;
    `;

const phonePrefix = [
    {
        value: '852',
        label: '+852'
    },
    {
        value: '853',
        label: '+853'
    },
    {
        value: '86',
        label: '+86'
    }
];



function ForgetUserName() {

    const [selectPhonPrefix,setSelectPhonePrefix] = useState('852');

    const handlePhonePrefix = (event) => {
        setSelectPhonePrefix(event.target.value)
    }

    const [formValues, setFormValues] = useState({
        ContractNo: '',
    });
    const [formErrors, setFormErrors] = useState({
        ContractNo: false,
    });
    const [formMessage,setFormErrorMessage] = useState({
        ContractNo: '',
    })
    const [userValues, setUserValues] = useState({
        userName: '',
        reUserName: ''
    });
    const [userErrors, setUserErrors] = useState({
        userName: false,
        reUserName: false
    });
    const [userErrorMessage,setUserErrorMessage] = useState({
        userName: '',
        reUserName: ''
    })
    const handleFormValues = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    // const [otp,setOtp] = useState('')
    const otpRef = useRef('')
    const navigate = useNavigate()
    const [otpError,setOtpError] = useState(false)
    const [otpErrorResponse,setOtpErrorResponse] = useState('')
    const [responseError,setResponseError] = useState(false)
    const [errorResponse,setErrorResponse] = useState('')
    const [showCountDown,setShowCountDown] = useState(0)
    const [showCountDownMessage,setShowCountDownMessage] = useState('發送驗證碼')
    const [vervifyMobile,setVervifyMobile] = useState(false)
    const [showuserName, setShowuserName] = React.useState(false);
    let countDown = 0;
    let timer = 0;
    
    const handleClickShowuserName = () => setShowuserName((show) => !show);
    const sendOtp = () => {
        const registerUser = {
            contractNo: formValues.ContractNo,
            type: 'ForgetUserName',
        }
        const updatedErrors = {};
        const errorMessage = {};
        let hasErrors = false;

        Object.entries(formValues).forEach(([key, value]) => {
            if (!value.trim()) {
                updatedErrors[key] = true;
                errorMessage[key] = '此位置不能為空';
                hasErrors = true;
                setFormErrors(updatedErrors);
                setFormErrorMessage(errorMessage);
            } else {
                updatedErrors[key] = false;
            }
        });

        if (!hasErrors) {
            SendRequest.post({url: process.env.REACT_APP_API_URL + 'subscriber/checkContractNo',data: registerUser}).then(response => {
                if(response.status === 200){
                    countDown = 60;
                    startTimer();
                    setFormErrors(updatedErrors);
                    setFormErrorMessage(errorMessage);
                    setResponseError(false)
                    setErrorResponse('');
                } else {
                    setResponseError(true)
                }
            }).catch(err => {
                if (err.response.data.errors) {
                    console.log(err.response.data.errors);
                    Object.keys(err.response.data.errors).forEach((key, value) => {
                        updatedErrors[key] = true;
                        errorMessage[key] = err.response.data.message;
                    })
                    // setFormErrors(updatedErrors);
                    // setFormErrorMessage(errorMessage);
                    setResponseError(true);
                    setErrorResponse(err.response.data.message);
                } else {
                    setFormErrors(updatedErrors);
                    setFormErrorMessage(errorMessage);
                    setResponseError(true);
                    setErrorResponse(err.response.data.message);
                }
            })
        }
    }
    const startCountdown = () => {
        // Remove one second, set state so a re-render happens.
        countDown = countDown - 1;
        setShowCountDown(countDown);
        setShowCountDownMessage(countDown+'秒後重發');
         // Check if we're at zero.
        if (countDown == 0) { 
            clearInterval(timer);
            setShowCountDownMessage('發送驗證碼');
        }
    }

    const startTimer = () => {
        if (timer == 0 && countDown > 0) {
            timer = setInterval(startCountdown, 1000);
        }
    }

    const handleMouseDownuserName = (event) => {
        event.preventDefault();
    };

    const handleUserValues = (event) => {
        const { name, value } = event.target;
        setUserValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    const preForgetUserName = () => {
        const otp = otpRef.current.value

        const otpData = {
            contractNo: formValues.ContractNo,
            mobileNo: "ForgetUserName", // for matching api requirement
            otpCode: otp 
        }

        let hasErrors = false;

        if (!otpRef.current.value.trim()) {
            setOtpError(true)
            setOtpErrorResponse('此位置不能為空')
            hasErrors = true;
        } else {
            setOtpError(false)
        }

        if (!hasErrors) {
            SendRequest.post({url:process.env.REACT_APP_API_URL + 'subscriber/preForgetUserName',data: otpData}).then(response => {
                if(response.status === 200){
                    setOtpErrorResponse('')
                    setOtpError(false)
                    setVervifyMobile(true)
                } else {
                }
            }).catch(err => {
                setOtpErrorResponse(err.response.data.message)
                setOtpError(true)
            })
        }
    }

    const submitForgetUserName = () => {
        const otp = otpRef.current.value
        const updatedErrors = {};
        const errorMessage = {};
        const otpData = {
            contractNo: formValues.ContractNo,
            mobileNo: "ForgetUserName", // for matching api requirement
            otpCode: otp,
            userName: userValues.userName
        }

        let hasErrors = false;

        Object.entries(userValues).forEach(([key, value]) => {
            if (!value.trim()) {
                updatedErrors[key] = true;
                errorMessage[key] = '此位置不能為空';
                hasErrors = true;
                setUserErrors(updatedErrors);
                setUserErrorMessage(errorMessage);
            } else {
                updatedErrors[key] = false;
            }
            if(key === 'reUserName'){
                if(value !== userValues.userName.toString()){
                    updatedErrors['userName'] = true;
                    updatedErrors['reUserName'] = true;
                    errorMessage['userName'] = '';
                    errorMessage['reUserName'] = '兩次輸入的用戶名稱必須相同';
                    hasErrors = true;
                    setUserErrors(updatedErrors);
                    setUserErrorMessage(errorMessage);
                }
            }
        });

        if (!hasErrors) {
            SendRequest.post({url:process.env.REACT_APP_API_URL + 'subscriber/submitForgetUserName',data: otpData}).then(response => {
                if(response.status === 200){
                    setOtpErrorResponse('')
                    setOtpError(false)
                    setVervifyMobile(true)
                    navigate('/forget-username-success')
                } else {
                }
            }).catch(err => {
                if (err.response.data.errors) { // check if formated error, classification if possible
                    Object.keys(err.response.data.errors).forEach((key, value) => {
                        updatedErrors[key] = true;
                        errorMessage[key] = err.response.data.message;
                        if (key != 'userName' && key != 'reUserName') {
                            updatedErrors['userName'] = true;
                            updatedErrors['reUserName'] = true;
                            errorMessage['reUserName'] = err.response.data.message;
                        }
                    })
                    setUserErrors(updatedErrors);
                    setUserErrorMessage(errorMessage);
                } else {
                    setUserErrors(true);
                    setUserErrorMessage(err.response.data.message);
                }
            })
        }
    }

    return (
        <Grid textAlign="center">
            <TopBar/>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6}>
                    <StyledDiv>
                        忘記用戶名稱
                    </StyledDiv>

                </Grid>
            </Grid>
            <Grid marginTop={3} container justifyContent="center" alignItems="center" style={{ display : vervifyMobile ? "none" : "flex" }}>
                <Grid item xs={10} sm={6}>
                    <Box>
                        <Paper style={{padding: '5%'}}>
                        <Box display="flex" justifyContent="space-between">
                            <TextField
                                name='ContractNo'
                                id="filled-basic"
                                value={formValues.ContractNo}
                                onChange={handleFormValues}
                                error={formErrors.ContractNo}
                                helperText={formMessage.ContractNo}
                                label='請輸入合約編號'
                                margin='normal'
                                style={{ width: '100%' }}
                                variant="filled" />
                            </Box>
                            {
                                    responseError &&
                                    <FormHelperText style={{ color: '#FF395D', display: 'flex', justifyContent: 'center',}} id="outlined-weight-helper-text">{errorResponse}</FormHelperText>
                            }
                            {
                                    <FormHelperText style={{ color: '#FF395D', display: showCountDown ? "flex" : "none", justifyContent: 'center',}} id="outlined-weight-helper-text">我們已發送驗證碼予對應的聯絡號碼</FormHelperText>
                            }
                            <Box marginTop={3}>
                                <Button
                                    variant="filled"
                                    color="primary"
                                    size="medium"
                                    disabled={(!formValues.ContractNo) || showCountDown > 0}
                                    onClick={sendOtp}
                                    sx={{
                                        borderRadius: 20,
                                        borderColor: 'red',
                                        borderWidth: 2,
                                        width: '150px',
                                        color: 'red',
                                        fontWeight: 'bold'
                                    }}

                                >
                                    {showCountDownMessage}
                                </Button>
                            </Box>
                            <Box margin='auto' style={{width: '60%'}}>
                                <TextField
                                    error={otpError}
                                    id="filled-basic"
                                    inputRef={otpRef}
                                    label='輸入驗證碼'
                                    margin='normal'
                                    helperText={otpError ? otpErrorResponse : ""}
                                    style={{width: '100%'}}
                                    variant="filled"
                                    />
                            </Box>
                            <Box marginTop={3}>
                                <Button
                                    variant="filled"
                                    color="primary"
                                    size="medium"
                                    onClick={preForgetUserName}
                                    sx={{
                                        borderRadius: 20,
                                        borderColor: 'red',
                                        borderWidth: 2,
                                        width: '150px',
                                        color: 'red',
                                        fontWeight: 'bold'
                                    }}

                                >
                                    繼續
                                </Button>

                            </Box>
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
            <Grid marginTop={3} container justifyContent="center" alignItems="center" style={{ display : vervifyMobile ? "flex" : "none" }}>
                <Grid item xs={10} sm={6}>
                    <Box>
                        <Paper style={{padding: '5%'}}>
                            <Box>
                                <FormControl error={userErrors.userName} sx={{  width: '100%' }} variant="filled">
                                    <InputLabel htmlFor="outlined-adornment-userName" >建立新用戶名稱</InputLabel>
                                    <FilledInput
                                        name='userName'
                                        id="outlined-adornment-userName"
                                        value={userValues.userName}
                                        onChange={handleUserValues}
                                        type='text'
                                        style={{borderColor: userErrors.userName ? 'red' : undefined }}
                                        label="userName"
                                    />
                                    {
                                        userErrors.userName &&
                                        <FormHelperText id="outlined-weight-helper-text">{userErrorMessage.userName}</FormHelperText>
                                    }

                                </FormControl>

                                <FormControl error={userErrors.reUserName} sx={{  width: '100%', marginTop: '10px'}} variant="filled">
                                    <InputLabel htmlFor="outlined-adornment-userName">重覆輸入新用戶名稱</InputLabel>
                                    <FilledInput
                                        name='reUserName'
                                        id="outlined-adornment-userName"
                                        value={userValues.reUserName}
                                        onChange={handleUserValues}
                                        type='text'
                                        label="userName"
                                    />
                                    {
                                        userErrors.reUserName &&
                                        <FormHelperText id="outlined-weight-helper-text">{userErrorMessage.reUserName}</FormHelperText>
                                    }
                                    {
                                        responseError &&
                                        <FormHelperText style={{ color: '#FF395D'}} id="outlined-weight-helper-text">{errorResponse}</FormHelperText>
                                    }
                                </FormControl>
                                <Box marginTop={3}>
                                    <Button
                                        variant="filled"
                                        color="primary"
                                        size="medium"
                                        onClick={submitForgetUserName}
                                        sx={{
                                            borderRadius: 20,
                                            borderColor: 'red',
                                            borderWidth: 2,
                                            width: '150px',
                                            color: 'red',
                                            fontWeight: 'bold'
                                        }}

                                    >
                                        重設用戶名稱
                                    </Button>

                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default ForgetUserName
