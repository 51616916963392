import styles from "./Tabs.module.scss";

const Tabs = ({ options, value, onClick }) => {
    return (
        <div className={styles["tabs-container"]}>
            {
                options.map((option, index) => (
                    <div key={index} className={styles["tab"] + (value === option.key ? ' ' + styles['tab--active'] : '')} onClick={() => onClick(option.key)}>
                        {option.label}
                    </div>
                ))
            }
        </div>
    );
};

export default Tabs;