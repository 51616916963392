import { CircularProgress } from "@mui/material";

const Loading = ({ show }) => {
    return (
        show ? (
            <div style={{
                display: 'fixed',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
                display: 'flex',
                position: 'absolute',
                zIndex: '999',
                background: '#FFF',
            }}>
                <CircularProgress color="inherit" />
            </div>
        ) : ''
    );
};

export default Loading;
