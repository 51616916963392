import {
    Box,
    Grid,
    Button,
    Paper,
    Typography
} from "@mui/material";
import TopBar from "../../components/topBar/TopBar";
import styled from "styled-components";
import React from "react";
import Success from '../../assets/forgetPasswordSuccess.png'
import {Link} from "react-router-dom";
function ForgetPasswordSuccess() {

    const StyledDiv = styled.div`
      margin-top: 5%;
      font-weight: bold;
      font-size: larger;
      display: flex;
      flex-direction: row;
      align-content: center;
    `;


    return(
        <Grid textAlign="center">
            <TopBar/>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <StyledDiv>
                        忘記密碼
                    </StyledDiv>

                </Grid>
            </Grid>
            <Grid marginTop={3} container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <Box>
                        <Paper style={{ padding: '5%', minHeight: "700px", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div>
                                <Box >
                                    <img src={Success} style={{width: '15%', height: '15%', minWidth: '119px'}}/>
                                </Box>
                                <Box margin='auto' style={{ width: '60%', paddingTop: '30px', paddingBottom: '30px', display: 'inline'}} marginTop={3} justifyContent="center" alignItems='center'>
                                    <Typography variant="body" display="block" gutterBottom style={{ lineHeight:'16.94px',fontWeight: '400', fontSize: '14px' }}>
                                        密碼已成功更改。你可以使用新密碼登入。
                                    </Typography>
                                </Box>
                                <Box marginTop={3} >
                                    <Link to='/'>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            sx={{ borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '100px', color: 'red', fontWeight: 'bold' }}
                                        >
                                            登入
                                        </Button>
                                    </Link>
                                </Box>
                            </div>
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    )
}
export default ForgetPasswordSuccess
