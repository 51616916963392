import { Navigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import { Box, Grid } from '@mui/material';
import Sugession from '../../assets/home/questionSugession.svg'
import { useEffect, useState } from 'react';


const NonAuthRoute = ({ children }) => {

    const [cookies, setCookie] = useCookies(['subscriber_token']);
    const token = cookies.subscriber_token;
    if (!token) {
        return (<>{children}</>)
    } else {
        return <Navigate to="/home" replace />
    }
}

export default NonAuthRoute
