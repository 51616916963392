import {
    Box,
    Grid,
    Button,
    Paper,
    FormHelperText
} from "@mui/material";
import TextField from '@mui/material/TextField';
import TopBar from "../../components/topBar/TopBar";
import styled from "styled-components";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import SendRequest from "../../request/SendRequest";
import Loading from "../../components/Loading/loading";

const StyledDiv = styled.div`
      margin-top: 5%;
      font-weight: bold;
      font-size: larger;
      display: flex;
      flex-direction: row;
      align-content: center;
    `;

function Register() {

    const navigate = useNavigate()
    const [responseError,setResponseError] = useState(false)

    const [enquiryValues, setEnquiryValues] = useState({
        email: '',
        message: '',
    });
    const [enquiryErrors, setEnquiryErrors] = useState({
        email: false,
        message: false,
    });
    const [enquiryMessage,setEnquiryErrorMessage] = useState({
        email: '',
        message: '',
    })

    const [errorResponse,setErrorResponse] = useState('')
    const [loading,setLoading] = useState(false)


    const submitEnquiry = () => {
        let hasErrors = false;

        const updatedErrors = {};
        const errorMessage = {};
        const enquiryData = {
            name: enquiryValues.name,
            email: enquiryValues.email,
            type: "general",
            message: enquiryValues.message,
        }
        Object.entries(enquiryValues).forEach(([key, value]) => {
            if (!value.trim()) {
                updatedErrors[key] = true;
                errorMessage[key] = '此位置不能為空';
                hasErrors = true;
                setEnquiryErrors(updatedErrors);
                setEnquiryErrorMessage(errorMessage);
            } else {
                updatedErrors[key] = false;
            }
        });
        if (!hasErrors) {
            setLoading(true)

            const submit = SendRequest.post({url: process.env.REACT_APP_API_URL + 'enquiry/submit',data: enquiryData}).then(response => {
                if(response.status === 200){
                    navigate('/enquiry-success')
                }
            }).catch(err => {
                if (err.response.data.errors) { // check if formated error, classification if possible
                    Object.keys(err.response.data.errors).forEach((key, value) => {
                        updatedErrors[key] = true;
                        errorMessage[key] = err.response.data.message;
                    })
                    setEnquiryErrors(updatedErrors);
                    setEnquiryErrorMessage(errorMessage);
                } else {
                    setEnquiryErrors(updatedErrors);
                    setEnquiryErrorMessage(errorMessage);
                    setResponseError(true);
                    setErrorResponse(err.response.data.message);
                }
            })
            Promise.all([submit]).then(() => {
                setLoading(false)
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const handleEnquiryValues = (event) => {
        const { name, value } = event.target;
        setEnquiryValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }


    return(
        <Grid>
            <Loading show={loading} />

            <TopBar/>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <StyledDiv>
                        意見查詢
                    </StyledDiv>

                </Grid>
            </Grid>
            <Grid marginTop={3} container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <Box>
                        <Paper style={{ padding: '5%'}}>
                            <Box style={{ width :'100%'}}>
                                <Box display="flex" justifyContent="space-between">
                                    <TextField
                                        name='email'
                                        id="filled-basic"
                                        value={enquiryValues.email}
                                        onChange={handleEnquiryValues}
                                        error={enquiryErrors.email}
                                        helperText={enquiryMessage.email ? "此位置不能為空" : ""}
                                        label='輸入電郵地址'
                                        margin='normal'
                                        style={{ width: '100%' }}
                                        variant="filled" />
                                </Box>
                                <TextField
                                        name='message'
                                        id="filled-basic"
                                        value={enquiryValues.message}
                                        onChange={handleEnquiryValues}
                                        error={enquiryErrors.message}
                                        helperText={enquiryMessage.message}
                                        label='你的訊息'
                                        margin='normal'
                                        style={{ width: '100%'}}
                                        multiline
                                        minRows={12}
                                        variant="filled" 
                                        />
                                        {
                                            responseError &&
                                            <FormHelperText style={{ color: '#FF395D'}} id="outlined-weight-helper-text">{errorResponse}</FormHelperText>
                                        }
                            </Box>
                            <Box marginTop={3} display="flex" justifyContent="center">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    sx={{ borderRadius: 20, borderColor: 'red', borderWidth: 2, width: '100px', color: 'red', fontWeight: '500', fontSize: '14px'}}
                                    onClick={() => submitEnquiry()}
                                >
                                    發送
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    )
}
export default Register
