import React, { useEffect, useState } from "react";
import SendRequest from "../../request/SendRequest";
import IndexCard from "../../components/IndexCard";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import TopBar from "../../components/topBar/TopBar";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom"
import { HtmlContent, YouTube } from "../../components";
import AdContent from "../../components/AdContent/AdContent";
import moment from "moment/moment";
import Loading from "../../components/Loading/loading";

function CaringTipsDetail() {

    const [data, setData] = useState('');
    const [content, setContent] = useState([]);
    const params = useParams();
    const navigate = useNavigate()
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        fetchContent();
    }, []);


    const fetchContent = async () => {
        const fetchData = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'detail/caringtip/' + params.alias }).then(response => {
            setData(response.data.data);
            setContent(response.data.data.content)
        }).catch(err => {
            navigate('/404')
        })
        Promise.all([fetchData]).then(() => {
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    };




    return (
        <Grid>
            <Loading show={loading} />
            <TopBar />
            <Grid container className="index-header" maxWidth="750px" margin="auto">
                <Grid item xs={12} sm={12} >
                    <Box className="page-title" fontSize={18} fontWeight={700}>{data.index_title}</Box>
                </Grid>
            </Grid>
            <Grid container className="page-body" maxWidth="750px" margin="auto" style={{padding: '10px'}}>
                {
                    data.type == 'video' ? (
                        <Grid item xs={12} style={{ paddingTop: '15px', paddingBottom: '15px', height: '100%', borderRadius: '15px', overflow: 'hidden',  }}>
                            <YouTube link={data.video_link}/>
                        </Grid>
                    ) : (
                        <Grid item xs={12} style={{ height: '100%', borderRadius: '15px', overflow: 'hidden',  }}>
                            <img width="100%" src={data.thumbnail}></img>
                        </Grid>
                    )
                }
                <Grid item xs={12}><span>{moment(data.publish_from).format('Y年M月D日')}</span></Grid>
                <Grid item xs={12} sm={12} style={{paddingBottom: '15px'}}>
                    <Box className="itme-title" fontSize={18} fontWeight={700}>{data.title}</Box>
                </Grid>
                {content.map((data, key) => (
                    {
                        'wysiwyg':
                        (
                            <Box width="100%">
                                <Grid item xs={12} sm={12} style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                    <Box className="itme-title" fontSize={18} fontWeight={700}>{data.title}</Box>
                                </Grid>
                                <Grid item key={"grid-" + key} xs={12}>
                                    <HtmlContent
                                    content={data.content} />
                                </Grid>
                            </Box>
                        ),
                        'image':
                        (
                            <Box>
                                <Grid item xs={12} sm={12} style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                    <Box className="itme-title" fontSize={18} fontWeight={700}>{data.title}</Box>
                                </Grid>
                                <Grid item key={"grid-" + key} xs={12} style={{ paddingTop: '15px', paddingBottom: '15px', height: '100%', borderRadius: '15px', overflow: 'hidden',  }}>
                                    <img src={data.image} style={{ textAlign:'center', maxWidth:'100%' }}></img>
                                </Grid>
                            </Box>
                        ),
                        'adcontent':
                        (
                            <Grid item key={"grid-" + key} xs={12} >
                                <AdContent 
                                    title={data.title}
                                    image={data.adcontent}
                                    btnText={data.button_text}
                                    btnTo={data.button_link}
                                    content={data.description}
                                />
                            </Grid>
                        ),
                        'video' : 
                        (
                            <Box>
                                <Grid item xs={12} sm={12} style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                    <Box className="itme-title" fontSize={18} fontWeight={700}>{data.title}</Box>
                                </Grid>
                                <Grid item key={"grid-" + key} xs={12} style={{ paddingTop: '15px', paddingBottom: '15px', height: '100%', borderRadius: '15px', overflow: 'hidden',  }}>
                                    <YouTube link={data.video_link}/>
                                </Grid>
                            </Box>
                        ),
                        'button' : 
                        (
                            <Box width="100%">
                                <Grid item className="primary-btn" style={{ paddingTop: '15px', paddingBottom: '15px', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        href={data.button_link}
                                        target= "_blank"
                                        style={{ minWidth: "154px", fontSize: "14px", fontWeight: "500" }}
                                    >
                                        {data.button_text}
                                    </Button>
                                </Grid>
                            </Box>
                        )
                    }[data.type]
                ))}
            </Grid>
        </Grid>
    );
}

export default CaringTipsDetail