import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    Box,
} from "@mui/material";
import styled from "styled-components";
import Logo from '../../assets/logo.svg';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import MenuIcon from '../../assets/menu.svg'
import NexImage from "../../assets/next.svg";
import * as React from "react";
import { useCookies } from 'react-cookie';
import Icon from "../../components/Icon/Icon";
import SendRequest from "../../request/SendRequest";


const StyledImage = styled.img`
  height: 100%;
  width: 100%;
`;

function Register({ menu = "true"}) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showMenu, setShowMenu] = useState(menu)
    const [cookies, setCookie, removeCookie] = useCookies(['subscriber_token']);
    const [allSetting, setAllSetting] = useState({
        president_title: '',
        president_content: '',
        president_content_updated_at: '',
        report_detail_index_title: '',
        easy_home_care_index_title: '',
        easy_home_care_ehsPath: '',
        caring_tips_index_title: '',
        caring_tips_path: '',
        jetso_index_title: '',
        jetso_path: ''
    })
    const logOut = () => {
        removeCookie('subscriber_token', { path: '/' });
        removeCookie('subscriber_id', { path: '/' });
        //removeCookie('presidentContentUpdateAt');
        // navigate('/')
    }

    const openMenu = () => {
        setOpenDrawer(true)
    }

    const handClose = () => {
        setOpenDrawer(false)
    }

    useEffect(() => {
        if (cookies.subscriber_token) {
            fetchData()
        } else {
            setShowMenu(false);
        }
    }, [])
    const fetchData = async() => {
        const settings = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'settings/all' }).then(response => {
            if (response.status === 200) {
                setAllSetting(response.data.data)
            }
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                removeCookie('subscriber_token', { path: '/' });
                removeCookie('subscriber_id', { path: '/' });
                setShowMenu(false);
            }
        })
        Promise.all([settings]).then(() => {
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })

    }
    return (
        // <Grid>
        <div style={{zIndex: '1000',position: 'relative', fontFamily: `"Noto Sans TC", "Roboto", "Helvetica", "Arial", sans-serif`}}>
        <AppBar position="static" elevation={4}
            style={{ backgroundColor: '#FFFFFF' }}>
            <Toolbar>
                <IconButton
                    size="small"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    href="home"
                >
                    <img className="logo" src={Logo} />
                </IconButton>
                <Typography variant="h7" gutterBottom sx={{ fontWeight: 'bold', color: '#555555', whiteSpace: 'nowrap'}}>
                    訂戶專屬平台
                </Typography>
                <div style={{ flexGrow: 1 }}></div>
                {showMenu === "true" ? (
                    <IconButton size="small" edge="end" onClick={openMenu}>
                        <StyledImage style={{height: '100%', width: '100%' }} src={MenuIcon} />
                    </IconButton>
                ) : ''}
                {showMenu === "true"  ? (
                    <Drawer
                        anchor='right'
                        open={openDrawer}
                        onClose={handClose}
                    >

                        <Box
                            sx={{ minWidth: 319 }}
                            role="presentation"
                            fontWeight='bold'
                            style={{fontFamily: `"Noto Sans TC", "Roboto", "Helvetica", "Arial", sans-serif`, color: '#43474E'}}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px'}}>
                                <Box width='90%' color='#888' fontSize='20px'>
                                    目錄
                                </Box>
                                <Box width='10%'>
                                    <Icon className="cross-icon" name="cross" onClick={() => handClose()} style={{cursor: 'pointer'}}/>
                                </Box>
                            </Box>
                            <hr style={{ borderColor: '#888' }} />
                            <Box >
                                <Box display='flex' sx={{ padding: '0 20px' }}>
                                    <Link to="/jetso" style={{ display: 'contents' }}>
                                        <Box>
                                            {allSetting.jetso_index_title}
                                        </Box>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <Box>
                                            <img alt='reporte' src={NexImage} />
                                        </Box>
                                    </Link>
                                </Box>
                                <hr style={{ borderColor: '#888' }} />
                                <Box display='flex' sx={{ padding: '0 20px' }}>
                                    <Link to="/easy-home-service" style={{ display: 'contents' }}>
                                        <Box>
                                            {allSetting.easy_home_care_index_title}
                                        </Box>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <Box>
                                            <img alt='reporte' src={NexImage} />
                                        </Box>
                                    </Link>
                                </Box>
                                <hr style={{ borderColor: '#888' }} />
                                <Box display='flex' sx={{ padding: '0 20px' }}>
                                    <Link to="/caring-tips" style={{ display: 'contents' }}>
                                        <Box>
                                            {allSetting.caring_tips_index_title}
                                        </Box>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <Box>
                                            <img alt='reporte' src={NexImage} />
                                        </Box>
                                    </Link>
                                </Box>
                                <hr style={{ borderColor: '#888' }} />
                                <Box display='flex' sx={{ padding: '0 20px' }}>
                                    <Link to="/report/overall" style={{ display: 'contents' }}>
                                        <Box>
                                            {allSetting.report_detail_index_title}
                                        </Box>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <Box>
                                            <img alt='reporte' src={NexImage} />
                                        </Box>
                                    </Link>
                                </Box>
                                <hr style={{ borderColor: '#888' }} />
                                <Box display='flex' sx={{ padding: '0 20px' }}>
                                    <Link to="/user-profile" style={{ display: 'contents' }}>
                                        <Box>
                                        用戶資料
                                        </Box>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <Box>
                                            <img alt='reporte' src={NexImage} />
                                        </Box>
                                    </Link>
                                </Box>
                                <hr style={{ borderColor: '#888' }} />
                                {/* <Box display='flex' sx={{ padding: '0 20px' }}>
                                    <Link to="/subscriber" style={{ display: 'contents' }}>
                                        <Box>
                                            訂戶資料
                                        </Box>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <Box>
                                            <img alt='reporte' src={NexImage} />
                                        </Box>
                                    </Link>
                                </Box>
                                <hr style={{ borderColor: '#888' }} /> */}
                            </Box>
                            <Box justifyContent='center' textAlign='center'>
                                <a onClick={logOut} style={{ cursor:'pointer' }}>
                                    登出
                                </a>
                            </Box>
                            {/*<List>*/}
                            {/*    {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (*/}
                            {/*        <ListItem key={text} disablePadding>*/}
                            {/*            <ListItemButton>*/}
                            {/*                <ListItemText primary={text}/>*/}
                            {/*            </ListItemButton>*/}
                            {/*            <hr style={{borderColor: '#888'}}/>*/}
                            {/*        </ListItem>*/}
                            {/*    ))}*/}
                            {/*</List>*/}
                        </Box>
                    </Drawer>
                ) : ''}
            </Toolbar>
        </AppBar>
        </div>
        // </Grid>
    )
}

export default Register