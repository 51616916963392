import { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { InfoCard, DetailRecordCard } from "../../components";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import SendRequest from "../../request/SendRequest";
import TopBar from "../../components/topBar/TopBar";
import { Swiper, SwiperSlide } from 'swiper/react';
import NexImage from '../../assets/next.svg'
import moment from "moment";
import Loading from "../../components/Loading/loading";

const ReportDetail = () => {
    const location = useLocation();
    const { infoHelpData } = location.state;
    // const data = {
    //     name: "陳小美",
    //     gender: "F",
    //     title: "陳小美女士已得到我們的全面照料，一線通專員將24小時待命。",
    //     content: []
    // };
    const [jetso, setJetso] = useState([])
    const [easyHomeService, setEasyHomeService] = useState({
        data: [],
    })
    const [data, setData] = useState([{
        id: "",
        name: "",
        gender: "",
        title: "",
        content: "",
    }]);
    const [detailList, setDetailList] = useState([{
        icon: "",
        label: "",
        date: "",
        date_label: "",
    }]);

    const params = useParams();

    useEffect(() => {
        fetchContent();
    }, []);

    const sortOptions = ['', 'asc', 'desc']
    const [sortingIndex, setSortingIndex] = useState(0);
    const [sorting, setSorting] = useState(sortOptions[sortingIndex]);
    const [loading,setLoading] = useState(true)
    /**
     * Handle sorting click event
     */
    const handleSortingClick = () => {
        let newIndex = sortingIndex + 1;

        if (newIndex >= sortOptions.length) {
            newIndex = 0;
        }

        setSortingIndex(newIndex);
        setSorting(sortOptions[newIndex]);

        let newData = [...detailList];


        if (newIndex === 1) {
            newData = newData.sort((a, b) => moment(a.date) - moment(b.date));
        } else if (newIndex === 2) {
            newData = newData.sort((a, b) => moment(b.date) - moment(a.date));
        }

        setDetailList(newData);
    };


    const fetchContent = async () => {
        const monthlyactivity = await SendRequest.post({ url: process.env.REACT_APP_API_URL + 'subscription/monthlyactivity', data: { month: params.period, userid: params.userId } }).then(response => {
            let data = response.data.data.map((data, key) => (
                {
                    id: data.id,
                    name: data.name,
                    gender: data.gender,
                    title: data.content_title,
                    content: data.content,
                }
            ));
            setData(data);
            let detailList = response.data.data[0].time.map((time, key) => (
                {
                    icon: time.key,
                    label: time.label,
                    date: time.date,
                    date_label: time.date_label,
                }
            ));
            setDetailList(detailList);
        }).catch(err => {
            console.log(err);
            // navigate('/404')
        })
        const jetso = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'index/jetso' }).then(response => {
            if (response.status === 200) {
                setJetso(response.data.data)
            }
        })
        const easyhomeservice = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'index/easyhomeservice' }).then(response => {
            if (response.status === 200) {
                setEasyHomeService(response.data)
            }
        })

        Promise.all([monthlyactivity, jetso, easyhomeservice]).then(() => {
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    };

    function getYoutubeThumbnail(url, quality) {
        if (url) {
            var video_id, thumbnail, result;
            if (result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
                video_id = result.pop();
            } else if (result = url.match(/youtu.be\/(.{11})/)) {
                video_id = result.pop();
            }

            if (video_id) {
                if (typeof quality == "undefined") {
                    quality = 'high';
                }

                var quality_key = 'maxresdefault'; // Max quality
                if (quality == 'low') {
                    quality_key = 'sddefault';
                } else if (quality == 'medium') {
                    quality_key = 'mqdefault';
                } else if (quality == 'high') {
                    quality_key = 'hqdefault';
                }

                var thumbnail = "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
                return thumbnail;
            }
        }
        return false;
    }

    return (
        <Grid className="report-detail">
            <Loading show={loading} />
            <TopBar />
            <Box style={{
                maxWidth: '750px',
                margin: 'auto',
            }}>
                <Grid container className="index-header" maxWidth="750px" margin="auto">
                    <Grid item xs={12} sm={12} >
                        <Box className="page-title" fontSize={18} fontWeight={500}>用戶概況</Box>
                    </Grid>
                </Grid>
                <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                    <br></br>
                    {
                        <InfoCard data={data[0]} infoHelpData={infoHelpData} fullWidth="true"/>
                    }
                    <br></br>
                    <Grid item xs={12}>
                        <Box width='100%' marginBottom='5%'>
                            <Box textAlign='start' fontWeight='bold' display='flex'>
                                <Box>
                                    {easyHomeService.title}
                                </Box>
                                <div style={{ flexGrow: 1 }}></div>
                                <a href='easy-home-service'>
                                    <Box>
                                        查看更多
                                        <img alt='reporte' src={NexImage} />
                                    </Box>
                                </a>
                            </Box>
                            <Box style={{
                                height: '210px',
                                width: '100wh',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '2px 4px 18px -5px #DDE0E6A6',
                                borderRadius: '10px',
                                display: 'flex',
                                fontSize: '12px',
                                marginTop: '10px',
                            }}>
                                <Box style={{
                                    width: '100%',
                                    height: '90%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginLeft: '2%'
                                }}>
                                    {
                                        easyHomeService.data.slice(0, 4).map((item, key) => (
                                            <Box key={"ehs_"+key} className="easy-home-service-item" width='50%' height='95%' justifyContent='center'
                                                style={{ overflow: 'hidden' }} marginRight='3%'>
                                                <a href={"easy-home-service/detail/"+item.alias}>
                                                    <Box style={{
                                                        width: '100%',
                                                        height: '70%',
                                                        overflow: 'hidden',
                                                        borderRadius: '10px'
                                                    }}>
                                                        <img alt='chatWith' src={item.type == 'video' ? getYoutubeThumbnail(item.video_link, 'medium') : item.thumbnail} style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            borderRadius: '20px'
                                                        }} />
                                                    </Box>
                                                    <Box textAlign='start' style={{
                                                        width: '100%',
                                                        height: '30%',
                                                        lineHeight: '1.5',
                                                        fontWeight: '500',
                                                        marginTop: '1%'
                                                    }}>
                                                        <Box>
                                                            {item.title}
                                                        </Box>
                                                        <Box style={{ color: '#888', fontWeight: '400' }}>
                                                            {item.subtitle}
                                                        </Box>
                                                    </Box>
                                                </a>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <br></br>
                    <div style={{display : detailList.length > 0 ? '' : 'none'}}>
                    <DetailRecordCard data={detailList} sorting={sorting} handleSortingClick={(item) => handleSortingClick(item)} />
                    </div>
                    <Grid item xs={12}>
                        <Box width='100%' style={{ paddingTop: "15px" }}>
                            <Box textAlign='start' fontWeight='bold' display='flex'>
                                <Box>
                                    為你推薦
                                </Box>
                                <div style={{ flexGrow: 1 }}></div>
                            </Box>
                            <Box style={{
                                minHeight: '120px',
                                maxHeight: '146px',
                                width: '100wh',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '2px 4px 18px -5px #DDE0E6A6',
                                borderRadius: '10px',
                                display: 'flex',
                                marginTop: '10px',
                                paddingTop: '15px',
                                padding: '13px',
                                width: '720px',
                            }}>
                                <Box style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginLeft: '2%'
                                }}>
                                    <Box style={{ width: '100%', overflow: 'hidden' }}>
                                        <Swiper
                                            loop={true}
                                            spaceBetween={20}
                                            slidesPerView={3.2}
                                            centeredSlides={false}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false
                                            }}
                                            freeMode={true}
                                            style={{ paddingTop: '4px', width: "110%"}}
                                        >
                                            {
                                                jetso.map((item, key) => (
                                                    <SwiperSlide key={"jetso_"+key}>
                                                        <a href={"jetso/detail/" + item.alias}>
                                                            <img alt={item.alias} style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                                borderRadius: '15px'
                                                            }} src={item.type == 'video' ? getYoutubeThumbnail(item.video_link, 'medium') : item.thumbnail} />
                                                        </a>
                                                    </SwiperSlide>
                                                ))
                                            }

                                        </Swiper>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </div>
            </Box>
        </Grid>
    )
}

export default ReportDetail;