import Icon from "../Icon/Icon";
import Modal from "../Modal/Modal";
import styles from "./InfoHelpModal.module.scss";
import { useTranslation } from 'react-i18next';


const InfoHelpModal = ({ infoHelpData, show, onClose }) => {
    const { t } = useTranslation();
    
    return (
        <Modal show={show} overlayClose={false}>
            <div className={styles["info-help-modal"]}>
                <Icon className={styles["cross-icon"]} name="cross" onClick={() => onClose()} />
                <div className={styles["info-help-modal-container"]}>
                    {
                        infoHelpData.map((item, index) => (
                            <div key={index} className={styles["info-help-row"]}>
                                <Icon name={item.icon} className={styles["info-help-icon"]} />
                                <div className={styles["info-help-content"]}>
                                    <div className={styles["info-help-label"]}>
                                        {item.title}
                                    </div>
                                    <div className={styles["info-help-desc"]}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </Modal>
    );
};

export default InfoHelpModal;