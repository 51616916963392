import {
    Box,
    Grid,
    Button,
    Paper,
    Typography
} from "@mui/material";
import TextField from '@mui/material/TextField';
import TopBar from "../../components/topBar/TopBar";
import styled from "styled-components";
import React, {useRef, useState, useEffect } from "react";
import Phone from '../../assets/img.png';
import {useNavigate, useLocation} from "react-router-dom";
import SendRequest from "../../request/SendRequest";


const StyledDiv = styled.div`
      margin-top: 5%;
      font-weight: bold;
      font-size: larger;
      display: flex;
      flex-direction: row;
      align-content: center;
    `;
function SendSMS() {

    // const [otp,setOtp] = useState('')
    const otpRef = useRef('')
    const navigate = useNavigate()
    const [error,setError] = useState(false)
    const [errorResponse,setErrorResponse] = useState('')
    const [showCountDown,setShowCountDown] = useState(0)
    const [showCountDownMessage,setShowCountDownMessage] = useState('重新發送')
    const [registerBtn, setRegisterBtn] = useState(true)
    const location = useLocation();
    let countDown = 0;
    let timer = 0;

    useEffect(()=>{
        countDown = 60;
        startTimer();
    }, [] );

    const registerUserWithOtp = () => {
        setRegisterBtn(false)
        const otp = otpRef.current.value
        if (location.state && otp) {
            location.state.otpCode = otp
            SendRequest.post({url: process.env.REACT_APP_API_URL + 'subscriber/register', data: location.state}).then(response => {
                if (response.status === 200) {
                    navigate('/register-success')
                }
            }).catch(err => {
                setRegisterBtn(true)
                setError(true)
                setErrorResponse(err.response.data.message)
                //alert("發生錯誤：", err)
            })
        } else {
            setRegisterBtn(true)
            setError(true)
            //alert('請輸入驗證碼')
        }
    }

    const sendOtp = () => {
        const sendUser = {
            contractNo:location.state.contractNo,
            mobileNo: location.state.mobileNo,
        }
        SendRequest.post({url:process.env.REACT_APP_API_URL + 'subscriber/sendOtpMessage',data: sendUser}).then(response => {
            if(response.status === 200){
                countDown = 60;
                startTimer();
                // countdown
            }else {
            }
        }).catch(err => {
        })
    }

    const startCountdown = () => {
        // Remove one second, set state so a re-render happens.
        countDown = countDown - 1;
        setShowCountDown(countDown);
        setShowCountDownMessage(countDown+'秒後重發');
         // Check if we're at zero.
        if (countDown == 0) { 
            clearInterval(timer);
            setShowCountDownMessage('重新發送');
        }
    }

    const startTimer = () => {
        if (timer == 0 && countDown > 0) {
            timer = setInterval(startCountdown, 1000);
        }
    }

    // const handleOtp = (event) => {
    //     const value = event.target.value;
    //     // 使用正則表達式檢查是否為數字
    //     if (/^\d*$/.test(value)) {
    //         setOtp(value);
    //     }
    // } 
    
    return (
        <Grid style={{ textAlign: 'center' }}>
            <TopBar/>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6}>
                    <StyledDiv>
                        帳戶登記
                    </StyledDiv>

                </Grid>
            </Grid>
            <Grid marginTop={3} container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6}>
                    <Box>
                        <Paper style={{padding: '5%', minHeight: "500px"}}>
                            <Box>
                                <img src={Phone} alt='logo' style={{width: '15%', height: '15%', minWidth: '74px', padding: '15px 0'}}/>
                            </Box>
                            <Box margin='auto' style={{width: '60%', padding: '15px 0'}} marginTop={3} justifyContent="center"
                                 alignItems='center'>
                                <Typography variant="body" display="block" gutterBottom
                                            style={{lineHeight: '16.94px', fontWeight: '400', fontSize: "14px"}}>
                                    我們已發送了一個驗證碼到您登記的手機號碼，輸入驗證碼以完成註冊。
                                </Typography>
                            </Box>
                            <Box margin='auto' style={{width: '60%', padding: '15px 0'}}>
                                <TextField
                                    error={error}
                                    id="filled-basic"
                                    inputRef={otpRef}
                                    label='輸入驗證碼'
                                    margin='normal'
                                    helperText={error ? errorResponse : ""}
                                    variant="filled"
                                    style={{width: '100%'}}/>
                            </Box>
                            <Box marginTop={3} display="block" justifyContent="center" style={{ padding: '15px auto' }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    onClick={registerUserWithOtp}
                                    disabled={!registerBtn}
                                    sx={{
                                        borderRadius: 20,
                                        borderColor: 'red',
                                        borderWidth: 2,
                                        width: '100px',
                                        color: 'red',
                                        fontWeight: 'bold',
                                        marginRight:"8%"
                                    }}

                                >
                                    驗證
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    disabled={showCountDown > 0}
                                    onClick={sendOtp}
                                    sx={{
                                        borderRadius: 20,
                                        borderColor: 'red',
                                        borderWidth: 2,
                                        width: '100px',
                                        color: 'red',
                                        fontWeight: 'bold',
                                        whiteSpace: 'nowrap',
                                    }}

                                >
                                    {showCountDownMessage}
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default SendSMS
