import Card from "../Card/Card";
import { useState } from "react";
import HtmlContent from "../HtmlContent/HtmlContent";
import { Button } from "react-bootstrap";
import { Box, Grid } from "@mui/material";
import "./AdContent.scss";

const AdContent = ({ title, image, btnText, btnTo, content }) => {

    const [showInfoHelp, setShowInfoHelp] = useState(false);

    return (
        <>
            <Box className="ad-content">
                <Card>
                    <Grid container>
                        <Grid item style={{ minWidth:"87px", width: "20%", maxHeight:'100px',display: 'flex', justifyContent: 'center'}}>
                            <div className="image-wrapper" style={{ 
                                overflow: 'hidden',
                                borderRadius: '21px',
                                display: 'flex',
                                height: '100%',
                                justifyContent: 'center',
                            }}>
                                <img height="100%" src={image} />
                            </div>
                        </Grid>
                        <Grid item  className="right" style={{ width: "80%", display: 'flex', alignItems: 'center'}}>
                            <Grid container>
                                <Grid item className="description" style={{ display: 'block', paddingLeft: '10px' }}>
                                    <div>
                                        <div className="title"><span>{title}</span></div>
                                        <div className="content"><HtmlContent content={content} /></div>
                                    </div>
                                </Grid>
                                <Grid item className="primary-btn" style={{ alignItems: 'center', display: 'flex' }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        href={btnTo}
                                        target= "_blank"
                                        style={{ minWidth: "154px", fontSize: "14px", fontWeight: "500" }}
                                    >
                                        {btnText}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </>
    );
};

export default AdContent;