import { useState } from "react";
import Card from "../Card/Card";
import Icon from "../Icon/Icon";
import styles from "./DetailRecordCard.module.scss";
import { useTranslation } from 'react-i18next';
import moment from 'moment';


const DetailRecordCard = ({ data, sorting, handleSortingClick}) => {
    const { t } = useTranslation();

    let recordData = data; 


    const sortOptions = ['', 'asc', 'desc']

    /**
     * Function description
     */
    const sortIcon = () => {
        let icon = 'sort';

        if (sorting === sortOptions[1]) {
            icon = 'sort-up';
        } else if (sorting === sortOptions[2]) {
            icon = 'sort-down';
        }

        return icon;
    };

    /**
     * Determine if the bottom border should be shown
     */
    const showBottomBorder = (index) => {
        let remaining = data?.length % 3;

        if (remaining === 0 && index >= data?.length - 3) {
            return ''
        }

        if (data?.length - remaining > index) {
            return ' ' + styles["list-row-border"];
        }

        return ''
    };



    return (
        <>
            <Card fullWidth>
                <div className={styles["detail-record-container"]}>
                    <div className={styles["detail-record-container-heading"]}>
                        <div className={styles["heading-label"]}>{t("total_record", { num: data.length })}</div>
                        <div className={styles["heading-sorting"]} onClick={() => handleSortingClick()}>
                            <Icon className={styles["sort-icon"]} name={sortIcon()} />
                            <div className={styles["sorting-label"]}>{t("sort_by")}: {t("sort_by_date")}</div>
                        </div>
                    </div>

                    <div className={styles["detail-record-container-list"]}>
                        {
                            recordData?.length ? recordData.map((item, index) => (
                                <div key={index} className={styles["list-row"] + showBottomBorder(index)}>
                                    <Icon className={styles["row-icon"]} name={item.icon} />
                                    <div className={styles["row-info"]}>
                                        <div className={styles["info-label"]}>{item.label}</div>
                                        <div className={styles["info-date"]}>{item.date_label}</div>
                                    </div>
                                </div>
                            )
                            ) : null
                        }
                    </div>
                </div>
            </Card>
        </>
    );
};

export default DetailRecordCard;