import {
    Box,
    Grid,
    Paper,
} from "@mui/material";
import TopBar from "../../components/topBar/TopBar";
import { useEffect, useState } from "react";
import SendRequest from "../../request/SendRequest";
import SubscriberInfo from "../../components/SubscriberInfo/SubscriberInfo";
import Loading from "../../components/Loading/loading";

function Subscriber() {

    const [data, setData] = useState([{
        name: "",
        contract_no: "",
        mobile_no: "",
        phone_no: "",
        subscription_count: "",
        user_count: "",
    }]);
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        fetchContent();
    }, []);


    const fetchContent = async () => {
        const fetchData = await SendRequest.post({ url: process.env.REACT_APP_API_URL + 'subscription/subscriber' }).then(response => {
            if (response.status === 200) {
                setData(response.data.data.message);
            }
        })
        Promise.all([fetchData]).then(() => {
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    };

    return(
        <Grid textAlign="center">
            <Loading show={loading} />
            <TopBar/>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <Box className="page-title">訂戶資料</Box>
                </Grid>
            </Grid>
            <Grid marginTop={3} container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6} >
                    <Box>
                        <Paper>
                        <SubscriberInfo data={data} />
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    )
}
export default Subscriber
