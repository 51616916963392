import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from '../pages/Login'
import Home from '../pages/home/Home'
import Register from '../pages/register/register'
import SendSMS from "../pages/register/SendSMS";
import RegisterSuccess from "../pages/register/RegisterSuccess";
import ForgetAccountSuccess from "../pages/register/ForgetAccountSuccess";
import ForgetPassword from "../pages/register/ForgetPassword";
import ForgetUserName from "../pages/register/ForgetUserName";
import ForgetPasswordSuccess from "../pages/register/ForgetPasswordSuccess";
import ForgetUserNameSuccess from "../pages/register/ForgetUserNameSuccess";
import Enquiry from "../pages/home/Enquiry";
import EasyHomeServiceIndex from "../pages/content/EasyHomeServiceIndex";
import EasyHomeServiceDetail from "../pages/content/EasyHomeServiceDetail";
import JetsoIndex from "../pages/content/JetsoIndex";
import CaringTipsIndex from "../pages/content/CaringTipsIndex";
import NotFoundPage from "../pages/error/NotFoundPage";
import AuthRoute from "../components/AuthRoute/AuthRoute";
import CaringTipsDetail from '../pages/content/CaringTipsDetail';
import JetsoDetail from '../pages/content/JetsoDetail';
import OverallReport from '../pages/content/OverallReport';
import ReportDetail from '../pages/content/ReportDetail';
import Subscriber from '../pages/content/Subscriber';
import UserProfile from '../pages/content/UserProfile';
import NonAuthRoute from '../components/AuthRoute/NonAuthRoute';
import EnquirySuccess from '../pages/home/EnquirySuccess';
import NewUser from '../pages/register/NewUser';

function getKeepLogin(){
    const keepLogin = localStorage.getItem('keepLogin')
    if(keepLogin && keepLogin === 'true'){
        return '/home'
    }else {
        return '/login'
    }
}
const routes = [
    {
        path: '/',
        element:<Navigate to={getKeepLogin()} />
    },
    {
        path:'/home',
        element: <AuthRoute><Home/></AuthRoute>,
    
    },
    {
        path:'/login',
        element: <NonAuthRoute><Login/></NonAuthRoute>,
    },
    {
        path: '/sms',
        element: <NonAuthRoute><SendSMS/></NonAuthRoute>
    },
    {
        path: '/register',
        element: <NonAuthRoute><Register/></NonAuthRoute>
    },
    {
        path: '/register-success',
        element: <NonAuthRoute><RegisterSuccess/></NonAuthRoute>
    },
    {
        path: '/guest-enquiry-success',
        element: <NonAuthRoute><ForgetAccountSuccess/></NonAuthRoute>
    },
    {
        path: '/new-user',
        element: <NonAuthRoute><NewUser/></NonAuthRoute>
    },
    {
        path: '/forget-password',
        element: <NonAuthRoute><ForgetPassword/></NonAuthRoute>
    },
    {
        path: '/forget-username',
        element: <NonAuthRoute><ForgetUserName/></NonAuthRoute>
    },
    {
        path: '/forget-password-success',
        element: <NonAuthRoute><ForgetPasswordSuccess/></NonAuthRoute>
    },
    {
        path: '/forget-username-success',
        element: <NonAuthRoute><ForgetUserNameSuccess/></NonAuthRoute>
    },
    {
        path: '/enquiry',
        element: <AuthRoute><Enquiry/></AuthRoute>
    },
    {
        path: '/enquiry-success',
        element: <AuthRoute><EnquirySuccess/></AuthRoute>
    },
    {
        path: '/easy-home-service',
        element: <AuthRoute><EasyHomeServiceIndex/></AuthRoute>
    },
    {
        path: '/jetso',
        element: <AuthRoute><JetsoIndex/></AuthRoute>
    },
    {
        path: '/caring-tips',
        element: <AuthRoute><CaringTipsIndex/></AuthRoute>
    },
    {
        path: '/easy-home-service/detail/:alias',
        element: <AuthRoute><EasyHomeServiceDetail/></AuthRoute>
    },
    {
        path: '/caring-tips/detail/:alias',
        element: <AuthRoute><CaringTipsDetail/></AuthRoute>
    },
    {
        path: '/jetso/detail/:alias',
        element: <AuthRoute><JetsoDetail/></AuthRoute>
    },
    {
        path: '/report/overall',
        element: <AuthRoute><OverallReport/></AuthRoute>
    },
    {
        path: '/report/detail/:userId/:period',
        element: <AuthRoute><ReportDetail/></AuthRoute>
    },
    {
        path: '/user-profile',
        element: <AuthRoute><UserProfile/></AuthRoute>
    },
    // {
    //     path: '/subscriber',
    //     element: <AuthRoute><Subscriber/></AuthRoute>
    // },
    {
        path: '*',
        element: <AuthRoute><NotFoundPage/></AuthRoute>
    },
]
export default routes;