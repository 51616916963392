import * as React from 'react';
import {
    Box, 
    Grid
} from "@mui/material";
import TopBar from "../../components/topBar/TopBar";
import ChatImage from '../../assets/chat_image.svg'
import NexImage from '../../assets/next.svg'
import { CloseButton } from "react-bootstrap";
import { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SendRequest from "../../request/SendRequest";
import StartButton from '../../assets/home/caringTips/start.svg'
import { InfoCard, Tabs } from '../../components';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/loading';

function Home() {
    const [loading,setLoading] = useState(true)
    const [borderColor, setBorderColor] = useState('white')
    const [allSetting, setAllSetting] = useState({
        president_title: '',
        president_content: '',
        president_content_updated_at: '',
        report_detail_index_title: '',
        easy_home_care_index_title: '',
        easy_home_care_ehsPath: '',
        caring_tips_index_title: '',
        caring_tips_path: '',
        jetso_index_title: '',
        jetso_path: ''
    })
    const [cookies, setCookie, removeCookie] = useCookies(['presidentContentUpdateAt']);
    const [jetso, setJetso] = useState([])
    const [caringTip, setCaringTip] = useState([])
    const [easyHomeServer, setEasyHomeServer] = useState([])
    const [showChat, setShowChat] = useState('none')
    const [isMobile, setIsMobile] = useState(false)
    const [caringTipItemCount, setCaringTipItemCount] = useState(false)
    const [tabValue, setTabValue] = useState(0);
    const [infoHelpData, setInfoHelpData] = useState([{
        icon: '',
        title: '',
        description: '',
    }]);
    const [data, setData] = useState([{
        id: "",
        name: "",
        gender: "",
        title: "",
        content: "",
        historyData: [{
            year: '',
            data: [
                {
                    key: '',
                    label: ''
                }
            ]
        }]
    }]);
    const [tabs, setTabs] = useState([{
        key: "",
        label: "",
    }]);
    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
        function handleResize() {
            if (window.innerWidth <= 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
            // setCaringTipItemCount(1 + (window.innerWidth * 0.7 - 298) / 151)
        }
        handleResize()
        window.addEventListener('resize', handleResize);
    }, [])


    const fetchData = async() => {
        const settings = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'settings/all' }).then(response => {
            if (response.status === 200) {
                setAllSetting(response.data.data)
            }
        })
        const jetso = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'index/jetso' }).then(response => {
            if (response.status === 200) {
                setJetso(response.data.data)
            }
        })
        const caringtip = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'index/caringtip' }).then(response => {
            if (response.status === 200) {
                setCaringTip(response.data.data)
            }
        })
        const easyhomeservice = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'index/easyhomeservice' }).then(response => {
            if (response.status === 200) {
                setEasyHomeServer(response.data.data)
            }
        })

        const shouldShowPresidentContent = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'getCloseAt' }).then(response => {
            if (response.status === 200) {
                if (response.data.data.show) {
                    setShowChat('flex')
                } else {
                    setShowChat('none')
                }
            }
        })

        const activity = await SendRequest.post({ url: process.env.REACT_APP_API_URL + 'subscription/activity' }).then(response => {
            let data = response.data.data.map((data, key) => (
                {
                    id: data.id,
                    name: data.name,
                    gender: data.gender,
                    title: data.content_title,
                    content: data.content,
                    historyData: data.history.map((history, key) => (
                        {
                            year: history.year,
                            data: history.data,
                        }
                    ))
                }
            ));
            setData(data);
            let infoHelpData = response.data.data[0].infoHelp.map((data, key) => (
                {
                    icon: data.icon,
                    title: data.label,
                    description: data.description,
                }
            ));
            setInfoHelpData(infoHelpData);
            let tabs = response.data.data.map((data, key) => (
                {
                    key: key,
                    label: data.name,
                }
            ));
            setTabs(tabs);


        }).catch(err => {
            console.log(err);
            navigate('/404')
        })

        Promise.all([settings, jetso, caringtip, easyhomeservice, activity, shouldShowPresidentContent]).then(() => {
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })

    }
    const handleButton = () => {
        setBorderColor('red')
    }

    const handleShowPresidentContent = async() => {
        setShowChat('none')
        const setCloseAt = await SendRequest.post({ url: process.env.REACT_APP_API_URL + 'updateCloseAt' }).then(response => {
            // if (response.status === 200) {
            //     if (response.data.show) {
            //         setShowChat('flex')
            //     } else {
            //         setShowChat('none')
            //     }
            // }
        })
        //setCookie('presidentContentUpdateAt', allSetting.president_content_updated_at.toString(), 30);
        //setCookieWithExpiry("presidentContentUpdateAt", allSetting.president_content_updated_at.toString(), 30);
        //localStorage.setItem("presidentContentUpdateAt",allSetting.president_content_updated_at.toString())
    }

    const setCookieWithExpiry = (key, value, expiryInDays) => {
        const now = new Date();
        const item = {
            value: value,
            expiry: now.getTime() + expiryInDays * 24 * 60 * 60 * 1000 // 过期时间戳
        };
        setCookie(key, JSON.stringify(item));
    }

    const getCookieWithExpiry = (key) => {
        const itemStr = cookies[key];
        if (!itemStr) {
            return null;
        }
        //const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > itemStr.expiry) {
            cookies.removeCookie(key);
            localStorage.removeItem(key); // 删除过期的值
            return null;
        }
        return itemStr.value;
    }

    function get_youtube_thumbnail(url, quality) {
        if (url) {
            var video_id, thumbnail, result;
            if (result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
                video_id = result.pop();
            } else if (result = url.match(/youtu.be\/(.{11})/)) {
                video_id = result.pop();
            }

            if (video_id) {
                if (typeof quality == "undefined") {
                    quality = 'high';
                }

                var quality_key = 'maxresdefault'; // Max quality
                if (quality == 'low') {
                    quality_key = 'sddefault';
                } else if (quality == 'medium') {
                    quality_key = 'mqdefault';
                } else if (quality == 'high') {
                    quality_key = 'hqdefault';
                }

                var thumbnail = "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
                return thumbnail;
            }
        }
        return false;
    }

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        swipeToSlide: true,
        spaceBetween: 20
    }

    return (
        <>
        <Loading show={loading} />
        {
        isMobile ? (
            <Grid className='homepage' style={{ overflow: 'hidden' }}>
                <TopBar />
                <Grid container spacing={2}>
                    <Grid item xs={12} className='president-message' >
                        <Box
                            style={{ backgroundColor: 'rgb(253,245,245)',whiteSpace: 'break-spaces'}}
                            borderRadius='20px'
                            padding='20px'
                            margin='17px'
                            textAlign='start'
                            display={showChat}
                            marginTop='15px'
                            boxShadow='2px 4px 18px -5px #DDE0E6A6'
                        >
                            <Box width='15%' style={{ minWidth: '38px' }}>
                                <img alt='chatIco' src={ChatImage} />
                            </Box>
                            <Box width='85%' lineHeight='22px'>
                                <Box display='flex' textAlign='start' style={{ justifyContent: 'space-between', paddingRight: '10px'}}>
                                    <Box width='90%' fontSize='16px' fontWeight='700'>
                                        {allSetting.president_title}
                                    </Box>
                                    <Box>
                                        <CloseButton onClick={handleShowPresidentContent}></CloseButton>
                                    </Box>
                                </Box>
                                <Box fontSize='13px' style={{paddingRight: '15px'}}>
                                    {allSetting.president_content}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {/*jetso 板块*/}
                    <Grid item xs={12}>
                        <Box width='95%' marginLeft='5%' marginRight='5%' >
                            <Box textAlign='start' fontWeight='bold' display='flex'>
                                <Box>
                                    {allSetting.jetso_index_title}
                                </Box>
                                <div style={{ flexGrow: 1 }}></div>
                                {/* <Box>
                                    查看更多
                                    <img alt='reporte' src={NexImage}/>
                                </Box> */}
                            </Box>
                            <Box style={{ width: '100%', overflow: 'hidden' }}>
                                {/*<div className="slider-container">*/}
                                {/*    <Slider {...settings} >*/}
                                {/*        <div >*/}
                                {/*            <img alt='userService' width='100%' src={Test} />*/}
                                {/*        </div>*/}
                                {/*        <div >*/}
                                {/*            <img alt='userService' width='100%' src={Test} />*/}
                                {/*        </div>*/}
                                {/*        <div >*/}
                                {/*            <img alt='userService' width='100%'  src={Test} />*/}
                                {/*        </div>*/}
                                {/*        <div >*/}
                                {/*            <img alt='userService' width='100%' src={Test} />*/}
                                {/*        </div>*/}
                                {/*    </Slider>*/}
                                {/*</div>*/}
                                <Swiper
                                    loop={true}
                                    spaceBetween={20}
                                    slidesPerView={1.17}
                                    centeredSlides={false}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false
                                    }}
                                    freeMode={true}
                                    style={{ paddingTop: '4px' }}
                                >
                                    {
                                        jetso.map((item) => (
                                            <SwiperSlide>
                                                <a href={"jetso/detail/" + item.alias}>
                                                    <img alt={item.alias} style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: '15px'
                                                    }} src={item.type == 'video' ? get_youtube_thumbnail(item.video_link, 'medium') : item.thumbnail} />
                                                </a>
                                            </SwiperSlide>
                                        ))
                                    }

                                </Swiper>
                            </Box>
                        </Box>
                    </Grid>
                    {/*caringtip 板块*/}
                    <Grid item xs={12} margin='5%' marginBottom='0' className='caringtip'>
                        <Box width='100%'>
                            <Box textAlign='start' display='flex' fontWeight='bold'>
                                <Box>
                                    {allSetting.caring_tips_index_title}
                                </Box>
                                <div style={{ flexGrow: 1 }}></div>
                                <a href='caring-tips' style={{display: 'flex', alignItems: 'center'}}>
                                    查看更多
                                    <Box>
                                        <img alt='reporte' src={NexImage} />
                                    </Box>
                                </a>
                            </Box>
                            <Box style={{
                                minHeight: '376px',
                                width: '100wh',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '2px 4px 18px -5px #DDE0E6A6',
                                borderRadius: '10px',
                                display: 'flex',
                                marginTop: '7px'
                            }}>
                                <Box style={{
                                    width: '100%',
                                    height: '100%',
                                    flexDirection: 'row',
                                    marginLeft: '2%',
                                    marginRight: '2%',
                                    padding: '10px',
                                    paddingTop: '20px'
                                }}>
                                    {
                                        caringTip.slice(0, 1).map((item, index) => {
                                            const date = new Date(item.published_from)
                                            const formattedDate = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
                                            const isVideo = item.type === 'video'
                                            return (
                                                <Box width='100%' height='48%' justifyContent='center'>
                                                    <a href={"caring-tips/detail/" + item.alias}>
                                                        <Box style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            overflow: 'hidden',
                                                            borderRadius: '10px',
                                                            position: 'relative'
                                                        }}>
                                                            {
                                                                isVideo ? (
                                                                    <img alt='chatWith'
                                                                        src={get_youtube_thumbnail(item.video_link, 'medium')}
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover',
                                                                            borderRadius: '20px',
                                                                        }} />
                                                                ) : (
                                                                    <img alt='chatWith1'
                                                                        src={item.thumbnail} style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover',
                                                                            borderRadius: '20px'
                                                                        }} />
                                                                )
                                                            }
                                                            <Box style={{
                                                                display: 'flex',
                                                                position: 'absolute',
                                                                bottom: '0',
                                                                left: '0',
                                                                width: '100%',
                                                                padding: '10px',
                                                                color: '#fff'
                                                            }}>
                                                                <Box style={{
                                                                    width: '90%',
                                                                    textAlign: 'start',
                                                                    fontWeight: 'bold'
                                                                }}>
                                                                    <Box fontSize={15}>
                                                                        {formattedDate}
                                                                    </Box>
                                                                    <Box fontSize={18}>
                                                                        {item.title}
                                                                    </Box>
                                                                </Box>
                                                                <Box style={{
                                                                    right: '0',
                                                                    width: '10%',
                                                                    display: item.type === 'video' ? '' : 'none'
                                                                }}>
                                                                    < img src={StartButton} alt="Play"
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '100%'
                                                                        }} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </a>
                                                </Box>
                                            )
                                        })
                                    }
                                    <Box display='flex' style={{ paddingTop: '20px', justifyContent: 'space-between'}} className="caringtip-bottom">
                                        {
                                            caringTip.slice(1, 3).map((item, index) => {
                                                const date = new Date(item.published_from)
                                                const formattedDate = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
                                                const isVideo = item.type === 'video'

                                                return (

                                                    <Box width='46%' justifyContent='center'
                                                        style={{ overflow: 'hidden' }}>
                                                        <a href={"caring-tips/detail/" + item.alias}>
                                                            <Box style={{
                                                                width: '100%',
                                                                height: '70%',
                                                                overflow: 'hidden',
                                                                borderRadius: '10px'
                                                            }}>

                                                                {
                                                                    isVideo ? (
                                                                        <img alt='chatWith'
                                                                            src={get_youtube_thumbnail(item.video_link, 'medium')}
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                objectFit: 'cover',
                                                                                borderRadius: '20px'
                                                                            }} />
                                                                    ) : (
                                                                        <img alt='chatWith1'
                                                                            src={item.thumbnail} style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                objectFit: 'cover',
                                                                                borderRadius: '20px'
                                                                            }} />
                                                                    )
                                                                }
                                                            </Box>
                                                            <Box textAlign='start' style={{
                                                                width: '100%',
                                                                height: '30%',
                                                                lineHeight: '1.5',
                                                                fontWeight: '500',
                                                                fontSize: '12px'
                                                            }}>
                                                                <Box style={{ color: '#888' }}>
                                                                    {formattedDate}
                                                                </Box>
                                                                <Box>
                                                                    {item.title}
                                                                </Box>
                                                            </Box>
                                                        </a>
                                                    </Box>
                                                )

                                            })
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {/*report 板块*/}
                    <Grid item xs={12} marginLeft='5%' marginRight='5%'>
                    <Box style={{
                            maxWidth: '750px',
                            margin: 'auto',
                        }}>
                            <Box>
                                <Box width='100%' display='flex' fontWeight='700' padding="0 0 10px 10px">
                                    <Box>
                                        {allSetting.report_detail_index_title}
                                    </Box>
                                    <div style={{ flexGrow: 1 }}></div>
                                    <a href='report/overall' style={{display: 'flex', alignItems: 'center'}}>
                                        <Box>
                                            查看更多
                                            <img alt='reporte' src={NexImage} />
                                        </Box>
                                    </a>
                                </Box>
                            </Box>
                            <div  style={{ paddingLeft:"5px",paddingRight:"5px" }}>
                                <Tabs options={tabs} value={tabValue} onClick={(value) => setTabValue(value)} />
                                {
                                    <InfoCard data={data[tabValue]} infoHelpData={infoHelpData}/>
                                }
                            </div>
                        </Box>
                    </Grid>
                    {/*easyhome板块*/}
                    <Grid item xs={12} margin='5%'>
                        <Box width='100%' marginBottom='5%'>
                            <Box textAlign='start' fontWeight='bold' display='flex'>
                                <Box>
                                    {allSetting.easy_home_care_index_title}
                                </Box>
                                <div style={{ flexGrow: 1 }}></div>
                                <a href='easy-home-service' style={{display: 'flex', alignItems: 'center'}}>
                                    <Box>
                                        查看更多
                                        <img alt='reporte' src={NexImage} />
                                    </Box>
                                </a>
                            </Box>
                            <Box style={{
                                minHeight: '192px',
                                width: '100wh',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '2px 4px 18px -5px #DDE0E6A6',
                                borderRadius: '10px',
                                display: 'flex',
                                marginTop: '10px',
                                paddingTop: '15px',
                                padding: '13px',
                            }}>
                                <Box style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginLeft: '2%'
                                }}>
                                    {
                                        easyHomeServer.slice(0, 2).map((item) => (
                                            <Box width='50%' height='95%' justifyContent='center'
                                                style={{ overflow: 'hidden' }} marginRight='3%'>
                                                <a href={"easy-home-service/detail/" + item.alias}>
                                                    <Box style={{
                                                        width: '100%',
                                                        height: '60%',
                                                        overflow: 'hidden',
                                                        borderRadius: '10px'
                                                    }}>
                                                        <img alt='chatWith' src={item.type == 'video' ? get_youtube_thumbnail(item.video_link, 'medium') : item.thumbnail} style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            borderRadius: '20px'
                                                        }} />
                                                    </Box>
                                                    <Box textAlign='start' style={{
                                                        width: '100%',
                                                        height: '30%',
                                                        lineHeight: '1.5',
                                                        fontWeight: 'bold',
                                                        marginTop: '1%',
                                                        fontSize: '12px'
                                                    }}>
                                                        <Box>
                                                            {item.title}
                                                        </Box>
                                                        <Box style={{ color: '#888' }}>
                                                            {item.subtitle}
                                                        </Box>
                                                    </Box>
                                                </a>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Grid>
        ) : (

            <Grid style={{ overflow: 'hidden' }}>
                <TopBar />
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} style={{ maxWidth: '1194px', margin: 'auto' }}>
                        <Grid item xs={3.5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box
                                        style={{ backgroundColor: 'rgb(253,245,245)', padding: '25px 10px', whiteSpace: 'break-spaces'}}
                                        borderRadius='20px'
                                        padding='20px'
                                        margin='0 5px 0 15px'
                                        textAlign='start'
                                        display={showChat}
                                        marginTop='10%'
                                        boxShadow='2px 4px 18px -5px #DDE0E6A6'
                                        fontSize={13}
                                    >
                                        <Box width='15%' style={{ minWidth: '38px' }}>
                                            <img alt='chatIco' src={ChatImage} />
                                        </Box>
                                        <Box width='85%' lineHeight='22px'>
                                            <Box display='flex' textAlign='start' style={{ justifyContent: 'space-between', paddingRight: '10px' }}>
                                                <Box width='90%' fontSize='20px' fontWeight='bold'>
                                                    {allSetting.president_title}
                                                </Box>
                                                <Box>
                                                    <CloseButton onClick={handleShowPresidentContent}></CloseButton>
                                                </Box>
                                            </Box>
                                            <Box marginTop='5%' style={{paddingRight: '15px'}}>
                                                {allSetting.president_content}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box style={{
                                        maxWidth: '750px',
                                        margin: 'auto',
                                        marginLeft: '10px',
                                    }}>
                                        <Box>
                                            <Box width='100%' display='flex' fontWeight='700' padding="0 0 10px 10px">
                                                <Box>
                                                    {allSetting.report_detail_index_title}
                                                </Box>
                                                <div style={{ flexGrow: 1 }}></div>
                                                <a href='report/overall' style={{display: 'flex', alignItems: 'center'}}>
                                                    <Box>
                                                        查看更多
                                                        <img alt='reporte' src={NexImage} />
                                                    </Box>
                                                </a>
                                            </Box>
                                        </Box>
                                        <div  style={{ paddingLeft:"5px",paddingRight:"5px" }}>
                                            <Tabs options={tabs} value={tabValue} onClick={(value) => setTabValue(value)} />
                                            {
                                                <InfoCard data={data[tabValue]} infoHelpData={infoHelpData}/>
                                            }
                                        </div>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={8.5}>
                            <div style={{ borderLeft: '1px solid #F8F8F8' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box width='100%' padding='15px' paddingTop='28px'>
                                            <Box textAlign='start' fontWeight='bold' display='flex'>
                                                <Box>
                                                    {allSetting.jetso_index_title}
                                                </Box>
                                                <div style={{ flexGrow: 1 }}></div>

                                            </Box>
                                            <Box style={{ width: '100%', overflow: 'hidden', paddingTop: '9px' }}>
                                                {/*<div className="slider-container">*/}
                                                {/*    <Slider {...settings} >*/}
                                                {/*        <div >*/}
                                                {/*            <img alt='userService' width='100%' src={Test} />*/}
                                                {/*        </div>*/}
                                                {/*        <div >*/}
                                                {/*            <img alt='userService' width='100%' src={Test} />*/}
                                                {/*        </div>*/}
                                                {/*        <div >*/}
                                                {/*            <img alt='userService' width='100%'  src={Test} />*/}
                                                {/*        </div>*/}
                                                {/*        <div >*/}
                                                {/*            <img alt='userService' width='100%' src={Test} />*/}
                                                {/*        </div>*/}
                                                {/*    </Slider>*/}
                                                {/*</div>*/}
                                                <Swiper
                                                    loop={true}
                                                    spaceBetween={22}
                                                    slidesPerView={1.23}
                                                    centeredSlides={false}
                                                    autoplay={{
                                                        delay: 2500,
                                                        disableOnInteraction: false
                                                    }}
                                                    freeMode={true}
                                                >
                                                    {
                                                        jetso.map((item) => (
                                                            <SwiperSlide>
                                                                <a href={"jetso/detail/" + item.alias}>
                                                                    <img alt={item.alias} style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'cover',
                                                                        borderRadius: '20px'
                                                                    }} src={item.type == 'video' ? get_youtube_thumbnail(item.video_link, 'medium') : item.thumbnail} />
                                                                </a>
                                                            </SwiperSlide>
                                                        ))
                                                    }

                                                </Swiper>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} className="caring-tip" style={{ padding: "0 0 0 34px" }}>
                                        <Box width='100%' overflow="hidden">
                                            <Box textAlign='start' display='flex' fontWeight='bold'>
                                                <Box>
                                                    {allSetting.caring_tips_index_title}
                                                </Box>
                                                <div style={{ flexGrow: 1 }}></div>
                                                <a href='caring-tips' style={{display: 'flex', alignItems: 'center'}}>
                                                    <Box>
                                                        查看更多
                                                        <img alt='report' src={NexImage} />
                                                    </Box>
                                                </a>
                                            </Box>
                                            <Box style={{
                                                height: '185px',
                                                width: '110%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                boxShadow: '2px 4px 18px -5px #DDE0E6A6',
                                                borderRadius: '10px',
                                                display: 'flex',
                                                padding: '10px',
                                                marginTop: '16px',
                                                marginLeft: '2px',
                                                marginBottom: '10px',
                                            }}>
                                                <Box style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}>
                                                    <Swiper
                                                        fontSize={12}
                                                        loop={true}
                                                        spaceBetween={22}
                                                        slidesPerView={5.2}
                                                        centeredSlides={false}
                                                        autoplay={{
                                                            delay: 2500,
                                                            disableOnInteraction: false
                                                        }}
                                                        freeMode={true}
                                                        style={{ width: '100%' }}
                                                    >
                                                        {
                                                            caringTip.map((item, index) => {
                                                                const date = new Date(item.published_from)
                                                                const formattedDate = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
                                                                const isVideo = item.type === 'video'

                                                                if (index === 0) {
                                                                    return (
                                                                        <SwiperSlide style={{ minWidth: "276px" }}>
                                                                            <Box width='100%' height='100%' justifyContent='center'
                                                                                style={{ overflow: 'hidden' }} marginRight='3%'>
                                                                                <a href={"caring-tips/detail/" + item.alias}>
                                                                                    <Box style={{
                                                                                        width: '100%',
                                                                                        height: '90%',
                                                                                        overflow: 'hidden',
                                                                                        borderRadius: '10px',
                                                                                        position: 'relative'
                                                                                    }}>
                                                                                        {
                                                                                            isVideo ? (
                                                                                                <img alt='chatWith'
                                                                                                    src={get_youtube_thumbnail(item.video_link, 'medium')}
                                                                                                    style={{
                                                                                                        width: '100%',
                                                                                                        objectFit: 'cover',
                                                                                                        borderRadius: '20px'
                                                                                                    }} />
                                                                                            ) : (
                                                                                                <img alt='chatWith1'
                                                                                                    src={item.type == 'video' ? get_youtube_thumbnail(item.video_link, 'medium') : item.thumbnail} style={{
                                                                                                        width: '100%',
                                                                                                        objectFit: 'cover',
                                                                                                        borderRadius: '20px'
                                                                                                    }} />
                                                                                            )
                                                                                        }
                                                                                        <Box style={{
                                                                                            display: 'flex',
                                                                                            position: 'absolute',
                                                                                            bottom: '0',
                                                                                            left: '0',
                                                                                            width: '100%',
                                                                                            padding: '10px',
                                                                                            color: '#fff'
                                                                                        }}>
                                                                                            <Box style={{
                                                                                                width: '90%',
                                                                                                textAlign: 'start',
                                                                                                fontWeight: 'bold'
                                                                                            }}>
                                                                                                <Box fontSize={12}>
                                                                                                    {formattedDate}
                                                                                                </Box>
                                                                                                <Box fontSize={14}>
                                                                                                    {item.title}
                                                                                                </Box>
                                                                                            </Box>
                                                                                            <Box style={{
                                                                                                right: '0',
                                                                                                width: '10%',
                                                                                                display: item.type === 'video' ? '' : 'none'
                                                                                            }}>
                                                                                                < img src={StartButton} alt="Play"
                                                                                                    style={{
                                                                                                        width: '100%',
                                                                                                        height: '100%'
                                                                                                    }} />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </a>
                                                                            </Box>
                                                                        </SwiperSlide>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <SwiperSlide className='small-item'>
                                                                            <Box width='100%' height='100%' justifyContent='center'
                                                                                style={{ overflow: 'hidden' }} marginRight='3%'>
                                                                                <a href={"caring-tips/detail/" + item.alias}>
                                                                                    <Box style={{
                                                                                        width: '100%',
                                                                                        height: '60%',
                                                                                        overflow: 'hidden',
                                                                                        borderRadius: '10px',
                                                                                        justifyContent: 'center',
                                                                                        display: 'flex',
                                                                                    }}>
                                                                                        {
                                                                                            isVideo ? (
                                                                                                <img alt='chatWith'
                                                                                                    src={get_youtube_thumbnail(item.video_link, 'medium')}
                                                                                                    style={{
                                                                                                        objectFit: 'cover',
                                                                                                        borderRadius: '20px'
                                                                                                    }} />
                                                                                            ) : (
                                                                                                <img alt='chatWith1'
                                                                                                    src={item.thumbnail} style={{
                                                                                                        objectFit: 'cover',
                                                                                                        borderRadius: '20px'
                                                                                                    }} />
                                                                                            )
                                                                                        }
                                                                                    </Box>
                                                                                    <Box textAlign='start' style={{
                                                                                        width: '100%',
                                                                                        height: '30%',
                                                                                        lineHeight: '1.5',
                                                                                        marginTop: '1%',
                                                                                        fontSize: '12px'
                                                                                    }}>
                                                                                        <Box style={{ color: '#888' }} fontWeight={400}>
                                                                                            {formattedDate}
                                                                                        </Box>
                                                                                        <Box fontWeight={500}>
                                                                                            {item.title}
                                                                                        </Box>
                                                                                    </Box>
                                                                                </a>
                                                                            </Box>
                                                                        </SwiperSlide>
                                                                    )
                                                                }

                                                            })
                                                        }
                                                    </Swiper>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingLeft: "34px" }}>
                                        <Box width='100%' marginBottom='5%'>
                                            <Box textAlign='start' fontWeight='bold' display='flex'>
                                                <Box>
                                                    {allSetting.easy_home_care_index_title}
                                                </Box>
                                                <div style={{ flexGrow: 1 }}></div>
                                                <a href='easy-home-service' style={{display: 'flex', alignItems: 'center'}}>
                                                    <Box>
                                                        查看更多
                                                        <img alt='reporte' src={NexImage} />
                                                    </Box>
                                                </a>
                                            </Box>
                                            <Box style={{
                                                height: '228px',
                                                width: '100wh',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                boxShadow: '2px 4px 18px -5px #DDE0E6A6',
                                                borderRadius: '10px',
                                                display: 'flex',
                                                fontSize: '12px',
                                                marginTop: '10px',
                                            }}>
                                                <Box style={{
                                                    width: '100%',
                                                    height: '90%',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginLeft: '2%'
                                                }}>
                                                    {
                                                        easyHomeServer.slice(0, 4).map((item) => (
                                                            <Box width='25%' height='95%' justifyContent='center'
                                                                style={{ overflow: 'hidden' }} marginRight='3%'>
                                                                <a href={"easy-home-service/detail/" + item.alias}>
                                                                    <Box style={{
                                                                        width: '100%',
                                                                        height: '70%',
                                                                        overflow: 'hidden',
                                                                        borderRadius: '10px'
                                                                    }}>
                                                                        <img alt='chatWith' src={item.type == 'video' ? get_youtube_thumbnail(item.video_link, 'medium') : item.thumbnail} style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover',
                                                                            borderRadius: '20px'
                                                                        }} />
                                                                    </Box>
                                                                    <Box textAlign='start' style={{
                                                                        width: '100%',
                                                                        height: '30%',
                                                                        lineHeight: '1.5',
                                                                        fontWeight: '500',
                                                                        marginTop: '1%'
                                                                    }}>
                                                                        <Box>
                                                                            {item.title}
                                                                        </Box>
                                                                        <Box style={{ color: '#888', fontWeight: '400' }}>
                                                                            {item.subtitle}
                                                                        </Box>
                                                                    </Box>
                                                                </a>
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                </Box>

            </Grid>
        )
        }
        </>
    )
}

export default Home