import {
    Box,
    Grid,
    Button,
    Paper,
    FormControl,
    MenuItem,
    FormHelperText,
    InputLabel,
    FilledInput,
    InputAdornment
} from "@mui/material";
import TextField from '@mui/material/TextField';
import TopBar from "../../components/topBar/TopBar";
import styled from "styled-components";
import React, {useRef, useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import SendRequest from "../../request/SendRequest";

const StyledDiv = styled.div`
      margin-top: 5%;
      font-weight: bold;
      font-size: larger;
      display: flex;
      flex-direction: row;
      align-content: center;
    `;

const phonePrefix = [
    {
        value: '852',
        label: '+852'
    },
    {
        value: '853',
        label: '+853'
    },
    {
        value: '86',
        label: '+86'
    }
];



function ForgetPassword() {

    const [selectPhonPrefix,setSelectPhonePrefix] = useState('852');

    const handlePhonePrefix = (event) => {
        setSelectPhonePrefix(event.target.value)
    }

    const [formValues, setFormValues] = useState({
        mobileNo: '',
    });
    const [formErrors, setFormErrors] = useState({
        mobileNo: false,
    });
    const [formMessage,setFormErrorMessage] = useState({
        mobileNo: '',
    })
    const [userValues, setUserValues] = useState({
        password: '',
        rePassword: ''
    });
    const [userErrors, setUserErrors] = useState({
        password: false,
        rePassword: false
    });
    const [userErrorMessage,setUserErrorMessage] = useState({
        password: '',
        rePassword: ''
    })
    const handleFormValues = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    // const [otp,setOtp] = useState('')
    const otpRef = useRef('')
    const navigate = useNavigate()
    const [otpError,setOtpError] = useState(false)
    const [otpErrorResponse,setOtpErrorResponse] = useState('')
    const [responseError,setResponseError] = useState(false)
    const [errorResponse,setErrorResponse] = useState('')
    const [showCountDown,setShowCountDown] = useState(0)
    const [showCountDownMessage,setShowCountDownMessage] = useState('發送驗證碼')
    const [vervifyMobile,setVervifyMobile] = useState(false)
    const [showPassword, setShowPassword] = React.useState(false);
    let countDown = 0;
    let timer = 0;
    
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const sendOtp = () => {
        const registerUser = {
            mobileNo: selectPhonPrefix + formValues.mobileNo,
            type: 'ForgetPassword',
        }
        const updatedErrors = {};
        const errorMessage = {};
        let hasErrors = false;

        Object.entries(formValues).forEach(([key, value]) => {
            if (!value.trim()) {
                updatedErrors[key] = true;
                errorMessage[key] = '此位置不能為空';
                hasErrors = true;
                setFormErrors(updatedErrors);
                setFormErrorMessage(errorMessage);
            } else {
                updatedErrors[key] = false;
            }
        });

        if (!hasErrors) {
            SendRequest.post({url: process.env.REACT_APP_API_URL + 'subscriber/checkMobile',data: registerUser}).then(response => {
                if(response.status === 200){
                    countDown = 60;
                    startTimer();
                    setFormErrors(updatedErrors);
                    setFormErrorMessage(errorMessage);
                    setResponseError(false)
                    setErrorResponse('');
                } else {
                    setResponseError(true)
                }
            }).catch(err => {
                if (err.response.data.errors) {
                    Object.keys(err.response.data.errors).forEach((key, value) => {
                        updatedErrors[key] = true;
                        errorMessage[key] = err.response.data.message;
                    })
                    setFormErrors(updatedErrors);
                    setFormErrorMessage(errorMessage);
                } else {
                    setFormErrors(updatedErrors);
                    setFormErrorMessage(errorMessage);
                    setResponseError(true);
                    setErrorResponse(err.response.data.message);
                }
            })
        }
    }
    const startCountdown = () => {
        // Remove one second, set state so a re-render happens.
        countDown = countDown - 1;
        setShowCountDown(countDown);
        setShowCountDownMessage(countDown+'秒後重發');
         // Check if we're at zero.
        if (countDown == 0) { 
            clearInterval(timer);
            setShowCountDownMessage('發送驗證碼');
        }
    }

    const startTimer = () => {
        if (timer == 0 && countDown > 0) {
            timer = setInterval(startCountdown, 1000);
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleUserValues = (event) => {
        const { name, value } = event.target;
        setUserValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    const preForgetPassword = () => {
        const otp = otpRef.current.value

        const otpData = {
            mobileNo: selectPhonPrefix + formValues.mobileNo,
            contractNo: "ForgetPassword", // for matching api requirement
            otpCode: otp 
        }

        let hasErrors = false;

        if (!otpRef.current.value.trim()) {
            setOtpError(true)
            setOtpErrorResponse('此位置不能為空')
            hasErrors = true;
        } else {
            setOtpError(false)
        }

        if (!hasErrors) {
            SendRequest.post({url:process.env.REACT_APP_API_URL + 'subscriber/preForgetPassword',data: otpData}).then(response => {
                if(response.status === 200){
                    setOtpErrorResponse('')
                    setOtpError(false)
                    setVervifyMobile(true)
                } else {
                }
            }).catch(err => {
                setOtpErrorResponse(err.response.data.message)
                setOtpError(true)
            })
        }
    }

    const submitForgetPassword = () => {
        const otp = otpRef.current.value
        const updatedErrors = {};
        const errorMessage = {};
        const otpData = {
            mobileNo: selectPhonPrefix + formValues.mobileNo,
            contractNo: "ForgetPassword", // for matching api requirement
            otpCode: otp,
            password: userValues.password
        }

        let hasErrors = false;

        Object.entries(userValues).forEach(([key, value]) => {
            if (!value.trim()) {
                updatedErrors[key] = true;
                errorMessage[key] = '此位置不能為空';
                hasErrors = true;
                setUserErrors(updatedErrors);
                setUserErrorMessage(errorMessage);
            } else {
                updatedErrors[key] = false;
            }
            if(key === 'rePassword'){
                if(value !== userValues.password.toString()){
                    updatedErrors['password'] = true;
                    updatedErrors['rePassword'] = true;
                    errorMessage['password'] = '';
                    errorMessage['rePassword'] = '兩次輸入的密碼必須相同';
                    hasErrors = true;
                    setUserErrors(updatedErrors);
                    setUserErrorMessage(errorMessage);
                }
            }
        });

        if (!hasErrors) {
            SendRequest.post({url:process.env.REACT_APP_API_URL + 'subscriber/submitForgetPassword',data: otpData}).then(response => {
                if(response.status === 200){
                    setOtpErrorResponse('')
                    setOtpError(false)
                    setVervifyMobile(true)
                    navigate('/forget-password-success')
                } else {
                }
            }).catch(err => {
                if (err.response.data.errors) { // check if formated error, classification if possible
                    Object.keys(err.response.data.errors).forEach((key, value) => {
                        updatedErrors[key] = true;
                        errorMessage[key] = err.response.data.message;
                        if (key != 'password' && key != 'rePassword') {
                            updatedErrors['password'] = true;
                            updatedErrors['rePassword'] = true;
                            errorMessage['rePassword'] = err.response.data.message;
                        }
                    })
                    setUserErrors(updatedErrors);
                    setUserErrorMessage(errorMessage);
                } else {
                    setUserErrors(true);
                    setUserErrorMessage(err.response.data.message);
                }
            })
        }
    }

    return (
        <Grid textAlign="center">
            <TopBar/>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10} sm={6}>
                    <StyledDiv>
                        忘記密碼
                    </StyledDiv>

                </Grid>
            </Grid>
            <Grid marginTop={3} container justifyContent="center" alignItems="center" style={{ display : vervifyMobile ? "none" : "flex" }}>
                <Grid item xs={10} sm={6}>
                    <Box>
                        <Paper style={{padding: '5%'}}>
                        <Box display="flex" justifyContent="space-between">
                            <TextField
                                id="filled-basic"
                                defaultValue='852'
                                onChange={handlePhonePrefix}
                                select
                                margin='normal'
                                style={{ width: '25%' }}
                                variant="filled" >
                                {
                                    phonePrefix.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                            <TextField
                                name='mobileNo'
                                id="filled-basic"
                                value={formValues.mobileNo}
                                onChange={handleFormValues}
                                error={formErrors.mobileNo}
                                helperText={formMessage.mobileNo}
                                label='輸入聯絡號碼'
                                margin='normal'
                                style={{ width: '70%' }}
                                variant="filled" />
                            </Box>
                            {
                                    responseError &&
                                    <FormHelperText style={{ color: '#FF395D', display: 'flex', justifyContent: 'center',}} id="outlined-weight-helper-text">{errorResponse}</FormHelperText>
                            }
                            <Box marginTop={3}>
                                <Button
                                    variant="filled"
                                    color="primary"
                                    size="medium"
                                    disabled={(!formValues.mobileNo) || showCountDown > 0}
                                    onClick={sendOtp}
                                    sx={{
                                        borderRadius: 20,
                                        borderColor: 'red',
                                        borderWidth: 2,
                                        width: '150px',
                                        color: 'red',
                                        fontWeight: 'bold'
                                    }}

                                >
                                    {showCountDownMessage}
                                </Button>
                            </Box>
                            <Box margin='auto' style={{width: '60%'}}>
                                <TextField
                                    error={otpError}
                                    id="filled-basic"
                                    inputRef={otpRef}
                                    label='輸入驗證碼'
                                    margin='normal'
                                    helperText={otpError ? otpErrorResponse : ""}
                                    style={{width: '100%'}}
                                    variant="filled"
                                    />
                            </Box>
                            <Box marginTop={3}>
                                <Button
                                    variant="filled"
                                    color="primary"
                                    size="medium"
                                    onClick={preForgetPassword}
                                    sx={{
                                        borderRadius: 20,
                                        borderColor: 'red',
                                        borderWidth: 2,
                                        width: '150px',
                                        color: 'red',
                                        fontWeight: 'bold'
                                    }}

                                >
                                    繼續
                                </Button>

                            </Box>
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
            <Grid marginTop={3} container justifyContent="center" alignItems="center" style={{ display : vervifyMobile ? "flex" : "none" }}>
                <Grid item xs={10} sm={6}>
                    <Box>
                        <Paper style={{padding: '5%'}}>
                            <Box>
                                <FormControl error={userErrors.password} sx={{  width: '100%' }} variant="filled">
                                    <InputLabel htmlFor="outlined-adornment-password" >建立新密碼</InputLabel>
                                    <FilledInput
                                        name='password'
                                        id="outlined-adornment-password"
                                        value={userValues.password}
                                        onChange={handleUserValues}
                                        type={showPassword ? 'text' : 'password'}
                                        style={{borderColor: userErrors.password ? 'red' : undefined }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                    {
                                        userErrors.password &&
                                        <FormHelperText id="outlined-weight-helper-text">{userErrorMessage.password}</FormHelperText>
                                    }

                                </FormControl>

                                <FormControl error={userErrors.rePassword} sx={{  width: '100%', marginTop: '10px'}} variant="filled">
                                    <InputLabel htmlFor="outlined-adornment-password">重覆輸入新密碼</InputLabel>
                                    <FilledInput
                                        name='rePassword'
                                        id="outlined-adornment-password"
                                        value={userValues.rePassword}
                                        onChange={handleUserValues}
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                    {
                                        userErrors.rePassword &&
                                        <FormHelperText id="outlined-weight-helper-text">{userErrorMessage.rePassword}</FormHelperText>
                                    }
                                    {
                                        responseError &&
                                        <FormHelperText style={{ color: '#FF395D'}} id="outlined-weight-helper-text">{errorResponse}</FormHelperText>
                                    }
                                </FormControl>
                                <Box marginTop={3}>
                                    <Button
                                        variant="filled"
                                        color="primary"
                                        size="medium"
                                        onClick={submitForgetPassword}
                                        sx={{
                                            borderRadius: 20,
                                            borderColor: 'red',
                                            borderWidth: 2,
                                            width: '150px',
                                            color: 'red',
                                            fontWeight: 'bold'
                                        }}

                                    >
                                        重設密碼
                                    </Button>

                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default ForgetPassword
