import styles from "./Modal.module.scss";
import { createPortal } from "react-dom";
import { useEffect, useState } from "react";

const Modal = ({
    children,
    show,
    onOverlayClose,
    overlayClose = true,
    ...attrs
}) => {
    const [domReady, setDomReady] = useState(false);

    useEffect(() => {
        setDomReady(true);
    }, []);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [show]);

    /**
     * Handle overlay close
     */
    const handleOverlayClose = () => {
        if (!overlayClose) return;
        onOverlayClose(false);
    };

    if (!domReady) return null;

    return show && createPortal(
        <div className={styles["modal-overlay"]} onClick={() => handleOverlayClose()} style={{ top: document.scrollingElement.scrollTop }}>
            <div className={styles["modal-wrapper"]} {...attrs}>
                {children}
            </div>
        </div>
        , document.getElementById("root")
    );
};

export default Modal;