import React, { useEffect, useState } from "react";
import SendRequest from "../../request/SendRequest";
import IndexCard from "../../components/IndexCard";
import { Box, Button, Grid } from "@mui/material";
import TopBar from "../../components/topBar/TopBar";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import Loading from "../../components/Loading/loading";

function JetsoIndex() {

    const [content, setContent] = useState([]);
    const [header, setHeader] = useState();
    const [pageCount, setPageCount] = useState(1);
    const [itemInPage, setItemInPage] = useState('');
    const [itemCount, setItemCount] = useState('');
    const [thumbnailSrc, setThumbnailSrc] = useState('');
    const [loading,setLoading] = useState(true)
    const [orderBy,setOrderBy] = useState('');
    const [seletedPage,setSeletedPage] = useState(1);

    useEffect(() => {
        fetchContent();
    }, []);

    function getYoutubeThumbnail(url, quality) {
        if (url) {
            var video_id, thumbnail, result;
            if (result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
                video_id = result.pop();
            } else if (result = url.match(/youtu.be\/(.{11})/)) {
                video_id = result.pop();
            }

            if (video_id) {
                if (typeof quality == "undefined") {
                    quality = 'high';
                }

                var quality_key = 'maxresdefault'; // Max quality
                if (quality == 'low') {
                    quality_key = 'sddefault';
                } else if (quality == 'medium') {
                    quality_key = 'mqdefault';
                } else if (quality == 'high') {
                    quality_key = 'hqdefault';
                }

                var thumbnail = "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
                return thumbnail;
            }
        }
        return false;
    }

    const fetchContent = async () => {
        const fetchData = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'index/jetso' }).then(response => {
            setContent(response.data.data);
            setPageCount(response.data.meta.last_page)
            setHeader(response.data.title);
            setThumbnailSrc(response.data.thumbnail);
            setItemCount(response.data.meta.total);
            setItemInPage(response.data.meta.from + '-' + response.data.meta.to);
        }).catch(err => {
        })
        Promise.all([fetchData]).then(() => {
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    };

    const handlePageClick = async (event) => {
        setLoading(true)
        let seletedPage = event.selected + 1
        const fetchData = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'index/jetso?page=' + (seletedPage) }).then(response => {
            setSeletedPage(seletedPage);
            setContent(response.data.data);
            setItemInPage(response.data.meta.from + '-' + response.data.mata.to);
        }).catch(err => {
        })
        Promise.all([fetchData]).then(() => {
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }

    const handleOrderButton = async () => {
        setLoading(true)
        let targetOrder = "oldest"; 
        if (orderBy === "oldest") {
            targetOrder = "latest"
        }
        const fetchData = await SendRequest.get({ url: process.env.REACT_APP_API_URL + 'index/jetso?page=' + (seletedPage) +'&order_by=' + (targetOrder) }).then(response => {
            setOrderBy(targetOrder);
            setContent(response.data.data);
            setItemInPage(response.data.meta.from + '-' + response.data.mata.to);
        }).catch(err => {
        })
        Promise.all([fetchData]).then(() => {
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    };

    // const [age, setAge] = React.useState('');
    // const [open, setOpen] = React.useState(false);

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    //     setOrderSeleted(true);
    // };

    // const handleClose = () => {
    //       setOpen(false);
    // };

    // const handleOpen = () => {
    //       setOpen(true);
    // };

    const handleEhsEnquiry = (event) => {

    }


    return (
        <Grid>
            <Loading show={loading} />
            <TopBar />
            <Grid container className="index-header" maxWidth="750px" margin="auto">
                <Grid item xs={12} sm={12} >
                    <Box className="page-title">{header}</Box>
                </Grid>
                <Grid item style={{ paddingLeft: '23px', fontSize: '14px' }} xs={6} sm={6} >
                    共{itemCount}項
                </Grid>
                <Grid item className="dropdown-order" style={{ 'paddingRight': '15px' }} xs={6} sm={6} >
                    <Button sx={{ display: 'block', mt: 2 }} onClick={handleOrderButton} className={orderBy}>
                        排序 : 按發佈日期
                    </Button>
                    {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="order-select-label" className={(orderSeleted ? "d-none " : "")}>按發佈日期</InputLabel>
                        <Select
                            labelId="order-select-label"
                            id="order-select"
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            value={age}
                            onChange={handleChange}
                        >
                        <MenuItem value="date">按發佈日期</MenuItem>
                        <MenuItem value="date">按發佈日期</MenuItem>
                        <MenuItem value="date">按發佈日期</MenuItem>
                        </Select>
                    </FormControl> */}
                </Grid>
            </Grid>
            <Grid container className="index-content" maxWidth="750px" margin="auto">
                {content.map((data, key) => (
                    <Grid key={"grid-" + key} item xs={12} md={6}>
                        <IndexCard
                            key={"card-" + key}
                            date={moment(data.published_from).format('Y年M月D日')}
                            thumbnailSrc={data.type == 'video' ? getYoutubeThumbnail(data.video_link, 'medium') : data.thumbnail}
                            title={data.title}
                            href={'jetso/detail/' + data.alias}
                        />
                    </Grid>
                ))
                }
            </Grid>
            <Grid container maxWidth="md" margin="auto" style={pageCount <= 1 ? { display: "none" } : { padding: "25px" }}>
                <Grid item xs={12}>
                    <ReactPaginate
                        className="navigation"
                        breakLabel="..."
                        nextLabel=""
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={pageCount}
                        previousLabel=""
                        renderOnZeroPageCount={null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box className="navigation" style={{ fontSize: "12px" }}>
                        {itemInPage} 項 共 {itemCount} 項
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default JetsoIndex